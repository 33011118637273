.customAccordion  .toggleIcon{
    text-align: left;
}
.customAccordion .card-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #F0F0F0;
    border-top: 1px solid #000;
    /* border-bottom: 2px solid #000; */
    height: 52px;
    padding: 10px;
    font-size: 24px;
}
.customAccordion .finalRow{
    border-bottom: 1px solid #000;
}
.customAccordion .accordion-collapse{
    padding: 0 60px;
    font-size: 1.6rem;
    border-bottom: 1px solid #000;
}
.customAccordion .accordion-collapse p{
    margin-top: 30px;
}

/*mobile view */
@media screen and (max-width: 576px) {
    .customAccordion .card-header{
        height: 52px;
        padding: 10px 0;
        font-size: 20px;
    }
    .customAccordion .accordion-collapse{
        padding: 0 10px;
        font-size: 1.3rem;
    }
}