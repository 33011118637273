.localityOuterContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 15px 30px;
    gap: 30px;
    min-height: 637px;
    background: #F0F0F0;
    font-family: 'Mukta';
    font-style: normal;
}
.localityTitle{
    height: 66px;
    font-family: 'Mukta';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 66px;
    margin: 0 auto;
    letter-spacing: -0.02em;
    color: #152536;
}
.localityInnerContainer{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0px;
    gap: 30px;
    width: 1452px;
    min-height: 520px;
    flex-wrap: wrap;
}
.stateContainer{    
    margin: 10px;
    width: 40%;   
}
.stateInnerContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
}
.indvidualLocations{
    width: 180px;
}
.StateName{    
    font-weight: 700;
    font-size: 28px;
    line-height: 47px;
    text-align: center;
    color: #152536;
    height: 47px;

}
.cityNameContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 18px;
    
}
.cityName{
    font-weight: 700;
    font-size: 17px;
    line-height: 28px;
    text-align: center;
    text-decoration-line: underline;
    color: #2E2D35;
    cursor: pointer;

}
.cityName:hover {
    color: #000000;
    
}
.loaderContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100% !important;
    height: 30vh;
}

/*mobile view */

@media screen and (max-width: 576px) {
    .localityOuterContainer{
        align-items: center;
        padding: 0px;
        gap: 5px;
        padding-top: 14px;
        min-height: 350px;
    }
    .localityTitle{
        font-size: 20px;
        line-height: 33px;
        text-align: center;
        height: 33px;
    }
    .localityInnerContainer{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 0px;
        min-height: 160px;
        gap: 5px;
    }
    .stateContainer{    
        margin: 0;
        width: 100%;   
    }
    .indvidualLocations{
        width: 100%;
    }
    
    .StateName{    
        font-size: 18px;
        line-height: 27px;
        text-align: center;
        height: 27px;
    }
    .cityNameContainer{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        flex-wrap: wrap;
        gap: 18px;
        
    }
    .cityName{
        font-weight: 500;
        font-size: 14px;
        line-height: 28px;  
        height: 28px;
    }
    .cityName:hover {
        color: #000000;
        
    }
}