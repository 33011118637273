.footerContainer{
    background: #E84E3B;
    font-family: 'Mukta';
    font-style: normal;
}
.customFooterContainer{
    background: #306AC0;
    font-family: 'Mukta';
    font-style: normal; 
}
.row1{
    display: flex;
    justify-content: space-between;
    padding: 30px 20px ;
    flex-wrap: wrap;
}
.r1col1{
    width: 320px;
}
.r1col2{
    font-weight: 400;
    font-size: 17px;
    line-height: 28px;
    width: 240px;
    color: #fff;
    text-decoration-line: underline;
    text-align: right;
}
.horizontalLine{
    border: 1px solid #FFFFFF;
    margin-top: 0px !important;
}
.row2{
    padding: 10px 20px;
}
.subTitle1, .subTitle2{
    height: 28px;
    font-weight: 700;
    font-size: 17px;
    line-height: 28px;
    color: #FFFFFF;
}

.footerPara{
    max-width: 720px;
    font-size: 17px;
    line-height: 28px;
    color: #FFFFFF;
}
.boldText1{
    font-weight: 700;
}
.logoContainer{
    width: 60px;
    margin: auto;
    display: flex;
    justify-content: space-around;
    margin-bottom: 10px;
}
.logoStyles{
    width: 24px;
    height: 24px;
}
.copyrightContainer{
    width: 250px;
    margin: 10px auto;
    font-weight: 400;
    font-size: 17px;
    line-height: 28px;
    color: #FFFFFF;
}
.copyrightContainer a .customerCopyrightContainer a{
    color: #FFFFFF !important;
}
.customerCopyrightContainer{
    width: 380px;
    margin: 10px auto;
    font-weight: 400;
    font-size: 17px;
    line-height: 28px;
    color: #FFFFFF;
}
.addressFooter{
    text-align: center;
    font-weight: 400;
    font-size: 17px;
    line-height: 28px;
    color: #FFFFFF;
}
.r1col2 p{
    cursor: pointer;
}
.r1col2 a{
    color: #FFFFFF !important;
}
.aboutLink{
    cursor: pointer;
}
.footerLinks{
    display: flex;
    width: 520px;
    margin: 0px auto;
    justify-content: space-between;
    padding-bottom: 20px;
}
.footerLinks p{
    font-weight: 400;
    font-size: 17px;
    color: #FFFFFF !important;
    text-decoration: underline;
    cursor: pointer;
}
.footerLinks span{
    font-weight: 400;
    font-size: 17px;
    color: #FFFFFF !important;
}


/*mobile view */
@media screen and (max-width: 768px) {
    .r1col2{
        padding: 20px 0 0 20px;
        line-height: 22px;
        margin-bottom: 0;
        text-align: left;

    }
    .row2{
        padding: 0 20px;
    }
    .subTitle1{
        height: 56px;
    }
}