.mainContainer{
    background-color: #F0F0F0;
    padding: 100px 20% !important;
}
.mainContainer h1{
    font-weight: 900px !important;
    font-size: 3rem;
}
.mainContainer p{
    text-align: justify;
}