.updatePwdContainer{
    background-color: #E9ECEF;
    min-height: 600px;
}
.container1{
    width: 94%;
    min-height: 200px;
    padding: 0px 0px 20px;
    background: #E9ECEF;
    border: 1px solid #CED4DA;
    margin: 10px auto;
}

.headerContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    gap: 10px;
    width: 100%;
    height: 59px;
    background: #E84E3B;
}
.headerContainerCP{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    gap: 10px;
    width: 100%;
    height: 59px;
    background: #306AC0;
}
.headerContainer p, .headerContainerCP p{
    font-family: 'Mukta';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 33px;
    text-align: center;
    color: #FFFFFF;
    margin: 0 !important;
}

.editPwdButton{
    padding: 10px 16px !important;
    width: 169px;
    height: 39px;
    background: #F8F9FA !important;
    border-radius: 4px !important;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 19px !important;
    text-align: center !important;
    color: #343A40 !important;
    border: none !important;
}
.editPwdButton:focus{    
    box-shadow: none !important;
}
.pwdDetailsContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px;
}

/* .container2{
    width: 94%;
    min-height: 200px;
    padding: 0px 0px 20px;
    background: #E9ECEF;
    border: 1px solid #CED4DA;
    margin: 10px auto;
} */
.column3{
    width: 362px;
}

.passwordInputStyle{
    width: 322px;
    height: 38px;
    background: #FFFFFF;
    padding: 4px 10px;
    border-radius: 4px !important;
    border: 1px solid #CED4DA ;
}
.passwordInputStyle:focus-visible{
    outline: none;
    border: 2px solid #CED4DA;
}
.inputGroupStyle{
    width: 40px;
    height: 38px;
    justify-content: center;
    align-items: center;
    background-color: #f0f0f08f !important;
    padding-left: 8px !important;
}
.pwdPara1{
    margin: 0px 0 2px 0  !important;
}
.pwdPara2{
    margin: 15px 0 8px 0  !important;
}
.loadingDiv{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    background-color: rgba(26, 30, 40, 0.2);
    height:100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.toastContainer{
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    z-index: 10;
    background-color: rgba(26, 30, 40, 0.2);
    height:100vh;
    width: 100%;  
}
.inputError{
    border: 1px solid #DC3545;
}
.inputError:focus-visible{
    outline: none;
    border:  2px solid #DC3545;
}
.pwdMatchSuccess{
    border: 1px solid #28A745;
}
.pwdMatchSuccess:focus-visible{
    outline: none;
    border:  2px solid #28A745;
}
.passwordToggleButton{
    cursor: pointer;
}
.errorMessage{
    font-size: 12px;
    line-height: 16px;
    color: #DC3545;
    margin:0;
}
.checkBox{
    width: 16px !important;
    height: 16px !important;
}

.passwordCheckRow{
    display: flex;
    align-items: center;
}
.passwordCheckRow p{
    font-weight: 400;
    font-size: 16px;
    line-height: 10px;
    color: #000000;
    margin: 14px 0 10px 16px !important;
}
