.outerContainer{
    background-color: #F0F0F0;
    padding: 15px 10px;
}
.signupTitle{
    height: 66px;
    font-weight: 700;
    font-size: 40px;
    line-height: 66px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #152536;
}
.para1{
    height: 47px;
    font-weight: 700;
    font-size: 28px;
    line-height: 47px;
    text-align: center;
    color: #152536;
}
.para2{
    height: 28px;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    color: #152536;
}
.para2 a{
    text-decoration: none;
    color: #E84E3B;
}
.para2 a:hover {
    color: #b65d4f;
}
.formContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 400;
    font-size: 16px;
    gap: 15px;
    line-height: 28px;
    color: #000000; 
}
.formTitle{
    font-weight: 700;
    font-size: 28px;
    line-height: 47px;
    color: #152536;
    margin-top: 10px;
}
.cardContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    min-height: 412px;
}
.cardStyle{
    margin-bottom: 20px;
    width: 400px !important;
    min-height: 514px !important;
    border: 1px solid #2E2D35 !important;
    margin-left: 5px ;
    border-radius: 4px !important;
}
.cardBodyStyle{
    padding: 14px !important;
}

.cardTitleContainer{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}
.cardTitle{
    min-width: 64%;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    color: #E84E3B;
    padding: 0 6px;
}

.cardTitleFee{
    min-width: 35% !important;
    font-family: 'Mukta';
    font-style: normal;
    justify-content: flex-end;
    font-weight: 700;
    font-size: 16px;
    text-align: right;
    color: #7D291F;
    margin-left: auto;
}
.descriptionContainer{
    margin: 10px 0;
    height: 220px !important;
    text-align: justify;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.descriptionContainer p{
    white-space: pre-line
}

.selectButton{    
    width: 217px;
    height: 58px;
    background: #E84E3B !important;
    padding: 15px !important;
    border: none !important;
    border-radius: 0 !important;

}
.selectButton:focus{
    box-shadow: none !important;
}
.buttonContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 20px;
    left: 19%;
}
.additionalFeeDiv{
    display: flex;
    font-family: 'Mukta';
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    justify-content: space-between;
    padding: 2px 10px;
    min-height: 30px;
}
.additionalFeeDetails{    
    width: 50%;
    margin: 0;
    text-align: left;
}
.additionalFeeAmount{
    width: 50%;
    margin: 0;
    text-align: right;
    color: #7D291F;
}

/*mobile view */
@media screen and (max-width: 576px) {
    .signupContainer{
        gap: 6px;
    }
    .signupTitle{
        height: 33px;
        font-size: 20px;
        line-height: 33px;
    }
    .para1{
        height: 20px;
        font-size: 16px;
        line-height: 20px;
    }
    .para2{
        height: 20px;
        font-size: 12px;
        line-height: 20px;
    }
}