.returnPolicyOuterContainer{
    background-color: #F0F0F0;
    min-height: 780px;
    padding: 40px 0px;
}
.returnPolicyInnerContainer h2, .returnPolicyInnerContainer h4 {
    text-align: left;
}
.returnPolicyInnerContainer{
    min-height: 780px;
    padding: 20px;
    width: 720px;
    margin: auto;
    text-align: justify;
    font-size: 16px;
}
.returnPolicyInnerContainer h2{
    font-size: 44px !important;
    font-weight: 800;
}
.returnPolicyInnerContainer h4{
    font-size: 22px !important;
    font-weight: 800;
}
/* tablet view */
@media screen and (min-width: 576px) and (max-width: 750px) {
    .returnPolicyInnerContainer{
        min-height: 780px;
        padding: 10px;
        width: 576px;
        margin: auto;
        text-align: justify;
        font-size: 16px;
    }
    .returnPolicyInnerContainer h2{
        font-size: 44px !important;
        font-weight: 800;
    }
    .returnPolicyInnerContainer h4{
        font-size: 22px !important;
        font-weight: 800;
    }
}
/* mobile view */
@media screen and (max-width: 576px) {
    .returnPolicyInnerContainer{
        min-height: 780px;
        padding: 20px;
        width: 290px;
        margin: auto;
        text-align: justify;
        font-size: 16px;
    }
    .returnPolicyInnerContainer h2{
        font-size: 36px !important;
        font-weight: 600;
    }
    .returnPolicyInnerContainer h4{
        font-size: px !important;
        font-weight: 600;
    }
}