.aboutInnerDiv1{
    background-color: #E84E3B;
    padding: 140px 40px;
    color: #FFF;
    font-size: 18px;
}
.aboutInnerDiv1 h1{    
    font-weight: 800;
    font-size: 3.2rem !important;
}
.aboutInnerDiv1 p{
    width: 75%;
    text-align: justify;
    margin-top: 40px;
}
.aboutInnerDiv2{
    display: flex;
    flex-direction: row;
    background-color: #F0F0F0;
    padding: 100px 40px;
    font-size: 17px;
    justify-content: center;
    flex-wrap: wrap;
}
.aboutInnerDiv2Col1{
    width: 49%;
}
.aboutInnerDiv2Col2{
    width: 49%;
    text-align: justify;
}
.aboutInnerDiv2Col1 h3{
    font-size: 2.8rem !important;
    font-weight: 700;
    color: #000;
}
.boldText{
    font-weight: 700;
}
.lastPara{
    margin-bottom: 40px;
}
.aboutInnerDiv3{
    display: flex;
    flex-direction: row;
    background-color: #F0F0F0;
    font-size: 17px;
    padding: 40px 40px 100px 40px;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.aboutInnerDiv3Col1{
    background-image: url("../../../Assets/Images/about-us-image.jpg");
    background-size: cover;
    width: 60%;
    height: 960px;
}
.aboutInnerDiv3Col2{
    background-color: #FFFFFF;
    width: 52%;
    z-index: 10;
    min-height: 560px;
    margin-left: -200px;
    padding: 55px;
    font-size: 17px;
    text-align: justify;
}

.aboutInnerDiv3Col2 h2{
    font-size: 3rem;
    font-weight: 800;
    margin-bottom: 28px;
}

/* table view */
@media screen and (min-width: 576px) and (max-width: 876px) {
    
    .aboutInnerDiv3{
        font-size: 16px;
        padding: 40px 25px 100px 25px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    .aboutInnerDiv3Col1{
        width: 85%;
        height: 460px;
    }
    .aboutInnerDiv3Col2{
        width: 85%;
        margin-left: 80px;
        margin-top: -50px;
        padding: 25px;
        font-size: 16px;
        min-height: 360px;
    }
}

/*mobile view */
@media screen and (max-width: 576px) {
    .aboutInnerDiv1{
        padding: 80px 25px;
        font-size: 16px;
    }
    .aboutInnerDiv1 h1{    
        font-weight: 700;
        font-size: 2.4rem !important;
    }
    .aboutInnerDiv1 p{
        width: 100%;
    }
    .aboutInnerDiv2{
        display: flex;
        flex-direction: column;
        padding: 50px 25px;
        font-size: 16px;
        justify-content: center;
        align-items: center;
    }
    .aboutInnerDiv2Col1{
        width: 100%;
    }
    .aboutInnerDiv2Col2{
        width: 100%;
    }
    .aboutInnerDiv2Col1 h3{
        font-size: 2.2rem !important;
    }
    .aboutInnerDiv3{
        display: flex;
        flex-direction: column;
        font-size: 16px;
        padding: 40px 10px 80px 10px;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    .aboutInnerDiv3Col1{
        width: 90%;
        background-size:cover;
        background-repeat: no-repeat;
        height: 560px;
    }
    .aboutInnerDiv3Col2{
        width: 90%;
        margin-left: 40px;
        margin-top: -50px;
        padding: 25px;
        font-size: 16px;
        text-align: justify;
    }
    .aboutInnerDiv3Col2 h2{
        font-size: 2.2rem;
        font-weight: 700;
    }
}