

.formRow{
    width: 100%;
    justify-content: space-around;
    padding: 4px 30px;
}
.row1Column input{
    width: 100%;
    height: 42px;
    padding: 0 4px;
}
.FullColumn input{
    width: 100%; 
    height: 42px;
}
.FullColumn textarea{
    width: 100%; 
    /* height: 90px; */
}
.addressInputError{
    background: #FFFFFF;
    border: 2px solid #DC3545;
    border-radius: 4px;
}
.addressInputError:focus-visible{
    outline: 1px solid #DC3545 !important;
}
.toastRow{
    display: flex;
    justify-content: center;
}