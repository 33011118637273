.cardStyles{
    align-items: flex-start;
    padding: 24px !important;
    gap: 10px;
    width: 439px;
    height: 257px;
    background: #120402 !important;
    border-radius: 4px !important;
    border: 2px solid #120402;
    color: #FFFFFF;
    font-size: 16px;
    margin: auto;
}
.cardBody{
    padding: 0!important;
}
.cardBody a{
    text-decoration: none;
}
.cardTitle{
    width: 200px;
    height: 27px;
    font-weight: 700 !important;
    line-height: 27px !important;
}
.cardText{
    font-weight: 400;
    line-height: 28px;
}

.cookiePolicyLink{
    color: #E84E3B;
    font-weight: 700;
    text-decoration: none;
}
.cookiePolicyLink:hover{
    color: #da4431;
}
.acceptButtonContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
}
.acceptButton{    
    width: 190.5px;
    height: 48px;
    background: #E84E3B !important;
    border-color: #E84E3B !important;
    border-radius: 99px !important;
    font-weight: 700 !important;
    font-size: 17px !important;
    line-height: 28px;
}
.acceptButton:focus{    
    box-shadow: none !important;
}
.buttonContainer{
    width: 390px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 10px 0;

}
.denyButton{
    width: 190.5px;
    height: 48px;
    background: #FAD2CC !important;
    border-color: #FAD2CC !important;
    border-radius: 99px !important;
    font-weight: 700 !important;
    font-size: 17px !important;
    line-height: 28px;
    color: #120402 !important;
}
.denyButton:focus{    
    box-shadow: none !important;
}

/*mobile view */
@media screen and (max-width: 576px) {
    .cardStyles{
        width: 350px;
        height: 257px;
        margin: auto !important;
    }
    .acceptButton{    
        width: 150px;
        height: 40px;
        font-size: 14px !important;
        font-weight: 500 !important;
        line-height: 24px;
    }
    .buttonContainer{
        width: 310px;
    
    }
    .denyButton{
        width: 140px;
        height: 40px;
        font-size: 14px !important;
        font-weight: 500 !important;
        line-height: 24px;
    }
}