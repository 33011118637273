.outerContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: url(../../../Assets/Images/login_bg.jpg);
    background-size: cover;
    min-height: 95vh;
    font-family: 'Mukta';
    font-style: normal;
}
.loginContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 0 20px 0;
    gap: 15px;
    width: 456px;
    min-height: 460px ;
    background: #FFFFFF;
    box-shadow: 0px 8px 4px rgba(0, 0, 0, 0.4);
}
.loginHeader{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 10px;
    gap: 10px;
    width: 456px;
    height: 48px;
    background: #E84E3B;
}
.loginHeader h6{
    font-weight: 700 !important;
    font-size: 17px !important;
    line-height: 28px !important;
    text-align: center;
    color: #FFFFFF !important;

}
.inputLabels{
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #000000;
    padding-left: 20px;
    margin-bottom: 6px !important;
}
.confirmPwdLabel{
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #000000;
    padding-left: 20px;
    margin-top: 6px !important;
    margin-bottom: 6px !important;
}

.buttonContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 0 0 20px;
    padding-top: 10px;
}
.loginButton{
    padding: 10px 16px !important;
    width: 168px !important;
    height: 39px !important;
    background: #E84E3B !important;
    border: 1px solid #E84E3B !important;
    border-radius: 4px !important;
    
}
.loginButton:focus{
    box-shadow:none !important;
}
.forgotPwdLink{
    display: flex;
    align-items: center !important;
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #E84E3B !important;
    cursor: pointer;
    margin-bottom: 0px !important;

}


.loadingDiv{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    background-color: rgba(26, 30, 40, 0.2);
    height:100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.errorMessage{
    font-size: 12px;
    line-height: 16px;
    color: #DC3545;
    margin: 0 0 10px 20px !important;
}
.inputStyle{
    width: 416px;
    height: 38px;
    margin-left: 20px;
    margin-bottom: 10px;
    padding: 4px 10px;
    background: #FFFFFF ;
    border: 1px solid #CED4DA ;
    border-radius: 4px ; 
}
.inputStyle:focus-visible{
    outline: 2px solid #CED4DA;
}
.passwordInputStyle{
    width: 376px;
    height: 38px;
    margin-left: 20px;
    margin-bottom: 10px;
    padding: 4px 10px;
    background: #FFFFFF ;
    border: 1px solid #CED4DA ;
    border-radius: 4px ;
}
.passwordInputStyle:focus-visible{
    outline: none;
    border: 2px solid #CED4DA ;
}
.inputError{
    border: 1px solid #DC3545 ;
}
.inputError:focus-visible{
    outline: none;
    border: 2px solid #DC3545 ;
}
.pwdMatchSuccess{
    border: 1px solid #28A745;
}
.pwdMatchSuccess:focus-visible{
    outline: none;
    border: 2px solid #28A745 ;
}

.inputGroupStyle{
    width: 40px;
    height: 38px;
    background-color: #f0f0f08f !important;
    padding-left: 8px !important;
}
.passwordToggleButton{
    cursor: pointer;
}
.passwordCheckRow{
    display: flex;
    align-items: center;
    margin-left: 34px;
}
.passwordCheckRow p{
    font-weight: 400;
    font-size: 16px;
    line-height: 8px;
    color: #000000;
    margin: 14px 0 10px 12px !important;
}