

.announcementaccordianContainer .accordion-button{
    height: 59px;
    background: #120402 !important;
    /* border: 1px solid #CED4DA; */
    /* border-radius: 4px 4px 0px 0px; */
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 27px;
    color: #FFFFFF !important;
}
.announcementaccordianContainer .accordion-button:focus{
    box-shadow: none !important;
}
.announcementaccordianContainer .accordion-header{
    border-radius: 4px 4px 0px 0px !important;
    border: 1px solid #CED4DA;

}
.announcementaccordianContainer .accordion-button::after{
    background-image: url("../../../Assets/Images/chevron-down.svg") !important;
}
.announcementaccordianContainer .accordion-button .collapsed::after{
    background-image: url("../../../Assets/Images/chevron-up.svg") !important;
}
.createAnnouncementForm{
    width: 440px !important;
    padding: 10px;
}
.createAnnouncementForm input{
    height: 37px;
    border-radius: 4px !important;
}
.createAnnouncementForm textarea{
    border: 1px solid #DEE2E6 !important;
    border-radius: 4px !important;
}
.createAnnouncementForm label{
    padding: 0;
    margin: 10px 0;
    font-size: 16px;
    line-height: 28px;
    color: #000000;
}
.createButtonContainer{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 1rem;
}
.createButtonContainer .createAnnouncement{
    padding: 10px 15px;
    width: 274px;
    height: 47px;
    background: #E84E3B !important;
    border-radius: 99px !important;
    border: none !important;
}
.createButtonContainer .createAnnouncement:focus{
    box-shadow: none !important;
}
.createAnnouncementForm .InputError{
    background: #FFFFFF;
    border: 2px solid #DC3545 !important;
}
.createAnnouncementForm .InputError:focus-visible{
    outline: 1px solid #DC3545 !important;
}
.conflictErrorMessage{
    color: #DC3545 !important;
}
.actionColumn{
    width: 15%;
    text-align: center;
}
.durationColumn{
    width: 15%
}
.messageColumn{
    width: 45%;
}
.urlColumn{
    width: 25%;
}
.announcementAction{
    font-weight: 700;
    font-size: 17px;
    line-height: 28px;
    text-decoration-line: underline;
    color: #E84E3B;
    cursor: pointer;
}
.urlContent a{
    font-weight: 700;
    font-size: 17px;
    line-height: 28px;
    color: #E84E3B !important;
    cursor: pointer;
    /* width: 25%; */

}
.datePickerStyle{
    width: 100%;
}
.datePickerError{
    width: 100%;
    border: 1px solid #DC3545 !important
}