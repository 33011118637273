/* input[type=radio]{
    display:none;
} */
.paymentCheckbox [type="checkbox"]:checked,
.paymentCheckbox [type="checkbox"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
.paymentCheckbox [type="checkbox"]:checked + label,
.paymentCheckbox [type="checkbox"]:not(:checked) + label
{
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #000;
}
.paymentCheckbox [type="checkbox"]:checked + label:before,
.paymentCheckbox [type="checkbox"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 20px;
    top: 10px;
    width: 18px;
    height: 18px;
    background: #fff;
}
.paymentCheckbox [type="checkbox"]:not(:checked) + label:before {
    border: 1px solid #adb0b3;
    border-radius: 3px;
}
.paymentCheckbox [type="checkbox"]:checked + label:after,
.paymentCheckbox [type="checkbox"]:not(:checked) + label:after {
    content: '';
    width: 16px;
    height: 16px;
    background-image: url("../../../Assets/Images/checkboxSelected.png");
    background-size: contain;
    background-position: center;
    position: absolute;
    top: 11px;
    left: 21px;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.paymentCheckbox [type="checkbox"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
.paymentCheckbox [type="checkbox"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}