.newLocationContainer{
    font-family: 'Mukta';
    font-style: normal;
    color: #000000;
    min-height: 700px;
}
.newLocationContainer h3{
    font-weight: 700;
    font-size: 22px;
    line-height: 33px;
}
.newLocationContainer p{
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
}
.formContainer1{
    padding-top: 10px;
    font-size: 16px;
    line-height: 28px;
    width: 440px;
}
.formContainer1 input{
    height: 37px !important;
    background: #FFFFFF;
    border: 1px solid #CED4DA;
    border-radius: 4px;
    padding: 8px 15px ;
    gap: 110px;
}
.formContainer1 select {
    border: 1px solid #CED4DA;
    border-radius: 4px;
}

.formContainer1 label{
    padding: 0;
}
.locationRow{
    display: flex;
    justify-content: space-between;
}
.locationRow input{
    width: 46%;
}
.locationRow select{
    width: 53%;
}
.butonContainer{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 1rem;
}
.createButton{
    padding: 10px 15px;
    gap: 10px;
    width: 274px;
    height: 47px;
    background: #E84E3B !important;
    border-radius: 99px !important;
    border: none !important;
}
.createButton:focus{
    box-shadow: none !important;
}
.inputError{
    background: #FFFFFF;
    border: 2px solid #DC3545 !important;
}
.inputError:focus-visible{
    outline: 1px solid #DC3545 !important;
}
.errorMessage{
    color: #DC3545;
    font-weight: 600;
    font-size: 18px;
    text-align: center;
}
.errorMessageInline{
    color: #DC3545;
    font-weight: 600;
    font-size: 18px;
}