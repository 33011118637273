.locationMgmtContainer .nav-item{
    width: 160px !important;
}
.locationMgmtContainer .nav-item .nav-link{
    width: 160px;
    height: 40px;
    background: #FFF !important;
    border-radius: 4px;
    padding: 5px 10px;    
    font-weight: 700;
    font-size: 17px;
    text-align: center !important;
    color: #2E2D35;
    border: 1px solid #120402 !important;
    margin-bottom: 4px;
}
.tabColumn{
    display: flex;
    flex-direction: column;
    width: 170px !important;
    height: 40px;
}
.tabContentSection{
    width: 88% !important;
    display: flex;
    flex-direction: column;
    flex: 5;
}
.locationMgmtContainer .nav-item .nav-link.active {
    width: 160px;
    height: 40px;
    background: #120402 !important;
    border-radius: 4px;
    padding: 5px 10px;    
    font-weight: 700;
    font-size: 17px;
    text-align: center !important;
    color: #FFFFFF !important;
    border: 1px solid #120402 !important;
}
.locationMgmtContainer .nav-link:hover{
    color: #2E2D35;
}
.locationMgmtContainer .nav-link.active:hover {
    color: #FFF;
}