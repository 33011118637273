.outerContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: url(../../../Assets/Images/login_bg.jpg);
    background-size: cover;
    min-height: 95vh;
    font-family: 'Mukta';
    font-style: normal;
}
.loginContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 15px;
    width: 456px;
    min-height: 332px;
    background: #FFFFFF;
    box-shadow: 0px 8px 4px rgba(0, 0, 0, 0.4);
}
.loginHeader{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 10px;
    gap: 10px;
    width: 456px;
    height: 48px;
    background: #E84E3B;
}
.loginHeader h6{
    font-weight: 700 !important;
    font-size: 17px !important;
    line-height: 28px !important;
    text-align: center;
    color: #FFFFFF !important;

}
.inputLabels{
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #000000;
    padding-left: 20px;
    margin-bottom: 6px !important;
}

.buttonContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 0 0 20px;
    padding-top: 10px;
}
.loginButton{
    padding: 10px 16px !important;
    width: 85px !important;
    height: 39px !important;
    background: #E84E3B !important;
    border: 1px solid #E84E3B !important;
    border-radius: 4px !important;
    
}
.loginButton:focus{
    box-shadow:none !important;
}
.forgotPwdLink{
    display: flex;
    align-items: center !important;
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #E84E3B !important;
    cursor: pointer;
    margin-bottom: 0px !important;

}
.forgotUsernameLink{
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #E84E3B !important;
    cursor: pointer;
    margin: 10px 20px !important;
}

.modalBody h5{
    font-weight: 600 !important;
    font-size: 20px !important;
    line-height: 33px !important;
    color: #000000 !important;
}
.modalBody p{
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #000000;
}
.modalButtonContianer{
    display: flex;
    justify-content: flex-end;
    margin: 14px 0;
}
.modalButtonContianer Button:focus{
    box-shadow: none !important;
}
.modalSubmitButton{
    width: 87px !important;
    height: 39px !important;
    background: #E84E3B !important;
    border-radius: 4px !important;
    margin-left: 15px;
    border: 2px solid #E84E3B !important;
}
.modalCancelButton{
    width: 86px !important;
    height: 39px !important;
    border: 1px solid #E84E3B !important;
    background: #FFFFFF !important;
    color: #E84E3B !important;
    border-radius: 4px !important;
}
.loadingDiv{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    background-color: rgba(26, 30, 40, 0.2);
    height:100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.errorMessage{
    color: #E84E3B !important;
    margin: 10px 20px !important;
}
.modalBody .errorMessage{
    margin: 10px 0 !important;
}

.inputStyle{
    width: 416px;
    height: 38px;
    margin-left: 20px;
    margin-bottom: 10px;
    padding: 4px 10px;
    background: #FFFFFF ;
    border: 1px solid #CED4DA ;
    border-radius: 4px ;
}
.passwordInputStyle{
    width: 376px;
    height: 38px;
    margin-left: 20px;
    margin-bottom: 10px;
    padding: 4px 10px;
    background: #FFFFFF ;
    border: 1px solid #CED4DA ;
    border-radius: 4px ;
}
.inputGroupStyle{
    width: 40px;
    height: 38px;
    background-color: #f0f0f08f !important;
    padding-left: 8px !important;
}
.passwordToggleButton{
    cursor: pointer;
}
.inputStyle:focus-visible{
    outline: none;
    border: 2px solid #CED4DA ;
}
.passwordInputStyle:focus-visible{
    outline: none;
    border-top: 2px solid #CED4DA ;
    border-bottom: 2px solid #CED4DA ;
    border-left: 2px solid #CED4DA ;
}

.modalBody .inputStyle{
    width: 100%;
    height: 38px;
    margin-left: 0px;
    background: #FFFFFF;
    border: 1px solid #CED4DA;
    border-radius: 4px;
    padding: 2px 15px;
    margin-top: 20px;
}
.modalBody .inputStyle:focus-visible{
    outline: 1px solid #CED4DA;
}
.inputError{
    width: 100%;
    height: 38px;
    background: #FFFFFF;
    border: 1px solid #DC3545 ;
    border-radius: 4px;
    padding: 2px 15px;
    margin-top: 20px;
}
.inputError:focus-visible{
    outline: 1px solid #DC3545 ;
}