.confirmModalButtonDiv{
    display: flex;
    justify-content: space-evenly;
    margin: 20px auto;
    width: 70%;
}
.confirmationModalDiv .confirmModalStyles .modal-dialog {
    width: 30% !important;
}

.confirmationNoButton{
    align-items: center;
    padding: 15px;
    gap: 10px;
    width: 156px;
    height: 48px;
    background: #28A745 !important;
    border: 2px solid #28A745 !important;
    line-height: 27px;
    color: #FFF !important ;
    border-radius: 0 !important;
    margin-right: 10px;
}
.confirmationNoButton:focus{
    box-shadow: none !important;
}
.confirmationYesButton{
    align-items: center;
    padding: 15px;
    gap: 10px;
    width: 156px;
    height: 48px;
    background: #DC3545 !important;
    border: 2px solid #DC3545 !important;
    border-radius: 0 !important;
}
.confirmationYesButton:focus{
    box-shadow: none !important;
}