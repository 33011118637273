.outerContainer{
    background-color: #F0F0F0;
    padding: 20px;
}
.signupTitle{
    height: 66px;
    font-weight: 700;
    font-size: 40px;
    line-height: 66px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #152536;
}
.para1{
    /* height: 47px; */
    font-weight: 700;
    font-size: 28px;
    line-height: 47px;
    text-align: center;
    color: #152536;
}
.para2{
    /* height: 28px; */
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    color: #152536;
}
.para2 a{
    text-decoration: none;
    color: #E84E3B;
}
.para2 a:hover {
    color: #b65d4f;
}
.formTitle{
    margin-top: 15px;
    font-family: 'Mukta';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 47px;
    text-align: center;
    color: #152536;
}
.formContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 480px !important;
    font-weight: 400;
    font-size: 16px;
    gap: 12px;
    line-height: 28px;
    color: #000000; 
}
.formContainer h5{
    margin-bottom: 0 !important;
    margin-left: -14px !important;
    font-family: 'Mukta';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
}
.formContainer label{
    padding: 0 !important;
    font-family: 'Mukta';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
}
.addressInput{
    background: #FFFFFF;
    border: 1px solid #CED4DA;
    border-radius: 4px;
    width: 420px !important;
    min-height: 38px;
}
.addressInputError{
    background: #FFFFFF;
    border: 2px solid #DC3545;
    border-radius: 4px;
    width: 420px !important;
    min-height: 38px;
}
.addressInputError:focus-visible{
    outline: 1px solid #DC3545 !important;

}
.errorWarning{
    color: #DC3545;
    width: 70%;
    margin: auto;
}
.nextButton{
    margin: 0 19% !important;
    gap: 10px;
    width: 217px !important;
    height: 58px;
    background: #E84E3B !important;
    border: #E84E3B !important;
    font-size: 17px !important;
    font-weight: 700 !important;
    line-height: 28px !important;
}
.nextButton:focus{
    box-shadow: none !important;
    
}
/*mobile view */
@media screen and (max-width: 576px) {
    .signupContainer{
        gap: 6px;
    }
    .signupTitle{
        height: 33px;
        font-size: 20px;
        line-height: 33px;
    }
    .para1{
        height: 20px;
        font-size: 16px;
        line-height: 20px;
    }
    .para2{
        height: 20px;
        font-size: 12px;
        line-height: 20px;
    }
    .formContainer{
        width: 330px !important;
        gap: 6px;
    }
    .addressInput{
        width: 330px !important;
    }
    .addressInputError{
        width: 330px !important;
     
    }
    .nextButton{
        margin: 0  18% !important;
        width: 300px;
        height: 47px;
        border-radius: 99px !important;
    }
}