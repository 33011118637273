.contactOuterContainer{
    display: flex;
    flex-wrap: wrap;
    padding: 50px 30px;
    background-color: #F0F0F0;
}
.innerContainer1{
    flex: 5;
    padding: 80px 30px;
}
.innerContainer1 h1{
    font-size: 48px !important;
    font-weight: 700;
}
.contactDetails{
    padding: 40px 0;
}
.contactDetails h5{
    font-weight: 800;
}
.innerContainer2{
    flex: 3.4;
}
.innerContainer2 img{
    height: 640px;
}
.innerSpan1{
    text-decoration: underline;
}
.contactDetails a{
    font-weight: 700;
    font-size: 17px;
    color: #212529;
}
.synkroEmailLink:hover{
    color: #E84E3B;
}
.loadingDiv{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    background-color: rgba(26, 30, 40, 0.2);
    height:100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
/*mobile view */
@media screen and (max-width: 767px) {
    .contactOuterContainer{
    padding: 30px 15px;

    }
    .innerContainer2 img{
        width: 340px ;
        height: 440px;
    }
}