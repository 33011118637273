.buttonStyle{
    width: 217px !important;
    height: 58px;
    color: #000 !important;
    background: #FFF !important;
    border: none !important;
    border-radius: 0 !important;
    font-size: 18px !important;
    font-weight: 600 !important;
}
.buttonStyle:hover{
    background-color: #FFF !important;
}
.buttonStyle:focus{    
    box-shadow: none !important;
}