
.updatePckgOuterContainer{
    background: #F0F0F0;
    padding: 20px;
    font-family: 'Mukta' !important;
    font-style: normal;
    padding-bottom: 120px;
}
.updatePckgInnerContainer{
    padding: 0px 0px 20px;
    gap: 10px;
    background: #E9ECEF;
    border: 1px solid #CED4DA;
    margin-bottom: 20px;
}
.updatePckgNav{
    display: flex;
    flex-direction: row;
    align-items: center !important;
    justify-content: space-between;
    padding: 10px;
    gap: 10px;
    height: 59px;
    background: #E84E3B;
}
.updatePckgNavCP{
    display: flex;
    flex-direction: row;
    align-items: center !important;
    justify-content: space-between;
    padding: 10px;
    gap: 10px;
    height: 59px;
    background: #306AC0;
}
.navBarTitle{
    font-weight: 500;
    font-size: 20px;
    line-height: 33px;
    text-align: center;
    color: #FFFFFF;
    margin-top: 10px;
}
.packagesHeading{
    font-weight: 500;
    font-size: 20px;
    line-height: 33px;
    color: #000000;
    margin: 10px 0 10px 10px;
}
.packagesCardContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    padding: 10px;
}
.packagesCard{
    display: flex;
    flex-direction: column;
    align-items: center; 
    width: 269.25px;
    background: #FFFFFF;
    border-radius: 4px;
    padding: 10px;
    padding-bottom: 80px;
    margin: 10px 5px 20px 5px;
    position: relative;
}
.packageAmountCircle{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 10px;
    width: 100px;
    height: 100px;
    border-radius: 99px;
    margin: 10px auto;
}
.packageAmountCircle p{
    font-weight: 700;
    font-size: 20px;
    line-height: 33px;
    text-align: center;
    color: #000000;
    margin-bottom: 0;
}
.defaultPackageCircle{
    background: #FAD2CC;
}
.activePackageCircle{
    background: #17A2B8
}
.activePackageCircle p{
    color: #FFFFFF !important;
}
.requestedPackageCircle{
    background: #120402
}
.requestedPackageCircle p{
    color: #FFFFFF !important;
}

.pckgName{
    font-weight: 500;
    font-size: 20px;
    line-height: 33px;
    text-align: center;
    color: #000000;

}
.pckgDescriptionLabel{
    font-weight: 700;
    font-size: 16px;
    line-height: 27px;
    text-align: center;
    color: #000000;
}
.pckgDescription{
    text-align: justify;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    margin-bottom: 60px;

}
.cardHorizontalRuler{
    height: 1px;
    color: #000000;
    background-color: #000000;
    margin: 20px 0;
    width: 96%;

}
.buttonContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    bottom: 20px;
    width: 100%;
    min-height: 110px;
}
.requestButton{
    width: 217px !important;
    height: 46px !important;
    background: #120402 !important;
    border-radius: 0 !important;
    border: none !important;
    font-weight: 700;
    font-size: 14px;
}
.requestButton:focus, .activeButton:focus {
    box-shadow: none !important;
}
.activeButton{
    width: 217px !important;
    height: 46px !important;
    background: #17A2B8 !important;
    border-radius: 0 !important;
    border: none !important;
    font-weight: 700;
    font-size: 14px;
}
.loadingDiv{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    background-color: rgba(26, 30, 40, 0.2);
    height:100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.cancelUpgradeRequest{
    margin: 10px 0 0 0;
    text-decoration: underline;
    color: #E84E3B;
    cursor: pointer;
}