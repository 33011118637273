.App {
font-family: 'Mukta', sans-serif;
 
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.FaqLayout_faqOuterContainer__XYODP .accordion .accordion-item .accordion-header .accordion-button:focus {
  border: none;
  box-shadow: none
}