.signupTitle{
    height: 66px;
    font-weight: 700;
    font-size: 40px;
    line-height: 66px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #152536;
}
.para1{
    height: 47px;
    font-weight: 700;
    font-size: 28px;
    line-height: 47px;
    text-align: center;
    color: #152536;
}
.para2{
    height: 28px;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    color: #152536;
}
.para2 a{
    text-decoration: none;
    color: #E84E3B;
}
.para2 a:hover {
    color: #b65d4f;
}
.formContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 400;
    font-size: 16px;
    gap: 15px;
    line-height: 28px;
    color: #000000; 
}
.formTitle{
    font-weight: 700;
    font-size: 28px;
    line-height: 47px;
    color: #152536;
    margin-top: 10px;
}
.cardContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
}
.cardStyle{
    margin-bottom: 20px;
    width: 320px !important;
    min-height: 251px !important;
}
.cardBodyStyle{
    padding: 14px 6px !important;
}

.cardTitleContainer{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}
.cardTitle{
    width: 64%;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    text-align: center;
    color: #E84E3B;
}

.cardTitleFee{
    width: 35% !important;
    font-family: 'Mukta';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    text-align: center;
    color: #7D291F;
}
.descriptionContainer{
    margin: 10px 0;
    min-height: 160px;
    text-align: center;
}

.selectButton{    
    width: 217px;
    height: 58px;
    background: #E84E3B !important;
    padding: 15px !important;
    border: none !important;
    border-radius: 0 !important;

}
.selectButton:focus{
    box-shadow: none !important;
}
.buttonContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

/*mobile view */
@media screen and (max-width: 576px) {
    .outerContainer{
        gap: 6px;
    }
    .signupTitle{
        height: 33px;
        font-size: 20px;
        line-height: 33px;
    }
    .para1{
        height: 20px;
        font-size: 16px;
        line-height: 20px;
    }
    .para2{
        height: 20px;
        font-size: 12px;
        line-height: 20px;
    }
    .formContainer{
        width: 330px !important;
        gap: 6px;
    }
}