.homeLayoutContainer{
    background-color: #F0F0F0;
    padding: 15px 30px;
    font-family: 'Mukta';
    font-style: normal;

}
.homeLayoutContainer .nav-link{
    width: 175px;
    height: 48px;
    padding: 10px 15px;    
    font-weight: 700;
    font-size: 17px;
    text-align: center !important;
    color: #2E2D35;
    background: #FFFFFF !important;
    border: 1px solid #E84E3B !important;
    border-radius: 4px 4px 0px 0px;
}

.homeLayoutContainer .nav-link.active {
    width: 175px;
    height: 48px;
    border-radius: 4px 4px 0px 0px;
    padding: 10px 15px;    
    font-weight: 700;
    font-size: 17px;
    text-align: center !important;
    color: #FFFFFF !important;
    background-color: #E84E3B !important;
}
.homeLayoutContainer .nav-link:hover{
    color: #2E2D35;
}
.homeLayoutContainer .nav-link.active:hover {
    color: #FFF;
}
.homeLayoutContainer .nav-tabs{
    border-bottom: 1px solid #E84E3B;
}