.faqOuterContainer{
    width: 80%;
    margin: auto;
    padding: 25px;
}
.faqTitle{
    text-align: center;
}
.faqOuterContainer h6{
    font-weight: 700;
}
.buttonContainer{
    margin-top: 20px;
}
