.signupContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px 30px;
    gap: 15px;
    min-height: 658px;
    background: #F0F0F0;    
    font-family: 'Mukta';
    font-style: normal;
}
.signupTitle{
    height: 66px;
    font-weight: 700;
    font-size: 40px;
    line-height: 66px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #152536;
    margin-bottom: 0;
}
.para1{
    height: 47px;
    font-weight: 700;
    font-size: 28px;
    line-height: 47px;
    text-align: center;
    color: #152536;
    margin-bottom: 0;
}
.para2{
    height: 28px;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    color: #152536;
    margin-bottom: 0;

}
.para2 a{
    text-decoration: none;
    color: #E84E3B;
}
.para2 a:hover {
    color: #b65d4f;
}
.formContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 460px !important;
    font-weight: 400;
    font-size: 16px;
    gap: 15px;
    line-height: 28px;
    color: #000000; 
}
.addressInput{
    background: #FFFFFF;
    border: 1px solid #CED4DA;
    border-radius: 4px;
    width: 420px !important;
    min-height: 38px;
}
.addressInputError{
    background: #FFFFFF;
    border: 2px solid #DC3545;
    border-radius: 4px;
    width: 420px !important;
    min-height: 38px;
}
.addressInputError:focus-visible{
    outline: 1px solid #DC3545 !important;

}
.submitButton{
    margin: 0 13% !important;
    gap: 10px;
    width: 290px;
    height: 58px;
    background: #E84E3B !important;
    border: #E84E3B !important;
    border-radius: 99px !important;
    font-size: 17px !important;
    line-height: 28px !important;
}
.submitButton:focus{
    box-shadow: none !important;
}
.para3{
    height: 29px;
    font-family: 'Mukta';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
}
.changeAddressLink {
    color: #E84E3B;
}
.changeAddressLink:hover{
    color: #E84E3B;
}
.validatedAddressOuterContainer{
    min-height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.validateAddressContainer{
    max-width: 408px;
    height: 47px;
}
.validateButton{
    width: 408px;
    height: 47px;
    background: #E84E3B !important;
    border-radius: 0px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border: none !important;
}
.validateButton:focus{
    border: none !important;
    box-shadow: none !important;
}
.errorWarning{
    color: #DC3545;
    width: 90%;
    margin: auto;
    text-align: center;
}
.errorWarning p{
    margin: 0 !important;
}
.loadingDiv{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    background-color: rgba(26, 30, 40, 0.2);
    height:100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.checkBoxContainer{
    margin-left: -20px;
    display: flex;
    flex-direction: row;
}

/*mobile view */
@media screen and (max-width: 576px) {
    .signupContainer{
        gap: 6px;
    }
    .signupTitle{
        height: 33px;
        font-size: 20px;
        line-height: 33px;
    }
    .para1{
        height: 20px;
        font-size: 16px;
        line-height: 20px;
    }
    .para2{
        height: 20px;
        font-size: 12px;
        line-height: 20px;
    }
    .formContainer{
        width: 330px !important;
        gap: 6px;
    }
    .addressInput{
        width: 330px !important;
    }
    .addressInputError{
        width: 330px !important;
     
    }
    .submitButton{
        margin: 0  auto !important;
        width: 300px;
        height: 47px;
        border-radius: 99px !important;
    }
    .para3{
        height: 54px;
        width: 330px;
        font-weight: 700;
        font-size: 16px;
        line-height: 27px;
    }
    .validateButton{
        width: 330px;
        height: 50px;
        border-radius: 0 !important;
    }
   
   
}