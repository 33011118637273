.outerContainer{
    background-color: #F0F0F0;
    min-height: 100vh;
    padding: 30px 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.createTitle{
    font-weight: 700;
    font-size: 40px;
    line-height: 66px;
    text-align: center !important;
    letter-spacing: -0.02em;
    color: #152536;
}
.packageDetails{
    font-weight: 700;
    font-size: 28px;
    line-height: 47px;
    text-align: center;
    color: #152536;
}
.para1{
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #152536;
}
.formContainer{
    margin-top: 20px;
    width: 520px;
}
.formContainer h3{
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    color: #000000;
    margin-left: -12px;
}
.formContainer label{
    padding: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #000000;
}
.passwordInputStyle{
    width: 380px;
    height: 38px;
    margin-bottom: 10px;
    padding: 4px 10px;
    background: #FFFFFF ;
    border: 1px solid #CED4DA ;
    border-radius: 4px ;
}
.passwordInputStyle:focus-visible{
    outline: none;
    border: 2px solid #CED4DA ;
}
.inputGroupStyle{
    width: 40px;
    height: 38px;
    justify-content: center;
    align-items: center;
    background-color: #f0f0f08f !important;
}
.emailInput{
    height: 38px;
    width: 420px !important;
    border: 1px solid #CED4DA;
    border-radius: 4px;
}
.emailInput:focus-visible{
    outline: none;
    border: 2px solid #CED4DA ;
}
.inputError{
    border: 1px solid #DC3545;
}
.inputError:focus-visible{
    outline: none;
    border: 2px solid #DC3545 ;
}
.pwdMatchSuccess{
    border: 1px solid #28A745 ;
}
.pwdMatchSuccess:focus-visible{
    outline: none ;
    border: 2px solid #28A745;
}
.checkBox{
    width: 16px !important;
    height: 16px !important;
}
.passwordCheckRow{
    display: flex;
    align-items: center;
}
.passwordCheckRow p{
    margin-left: 18px;
    font-weight: 400;
    font-size: 16px;
    line-height: 10px;
    color: #000000;
    margin-top: 18px;
}
.passwordToggleButton{
    cursor: pointer;
  }
.buttonContainer{
    display: flex;
    justify-content: space-between;
    width: 450px !important;
    margin-left: -26px;
}
.cancelButton{
    align-items: center;
    padding: 15px;
    gap: 10px;
    width: 217px;
    height: 57px;
    background: #FFFFFF !important;
    border: 2px solid #E84E3B !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    line-height: 27px;
    color: #E84E3B !important ;
    border-radius: 0 !important;
}
.cancelButton:focus{
    box-shadow: none !important;
}
.submitButton{
    align-items: center;
    padding: 15px;
    gap: 10px;
    width: 217px;
    height: 57px;
    background: #E84E3B !important;
    border: 2px solid #E84E3B !important;
    border-radius: 0 !important;
}
.submitButton:focus{
    box-shadow: none !important;
}
.errorMessage{
    font-size: 12px;
    line-height: 16px;
    color: #DC3545;
    margin:0;
}

.errorWarning{
    color: #DC3545;
    width: 100%;
    text-align: center;
    margin-left: -40px;
}
.loadingDiv{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    background-color: rgba(26, 30, 40, 0.2);
    height:100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

/*mobile view */
@media screen and (max-width: 576px) {
    .outerContainer{
        padding: 30px 25px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        min-height: 100px;
    }
    .createTitle{
        font-weight: 700;
        font-size: 32px;
        line-height: 44px;
    }
    .packageDetails{
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
    }
    .para1{
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
    }
    .formContainer{
        margin-top: 20px;
        width: 100%;
        padding: 10px 30px;

    }
    .formContainer h3{
        font-size: 16px;
        line-height: 22px;
        margin-left: 0;
    }
    .formContainer label{
        line-height: 20px;
    }
    .formContainer input{
        width: 420px;
    }
   
    .buttonContainer{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 380px !important;
    }
    .cancelButton{
        margin-bottom: 15px;
    }
}