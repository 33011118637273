.accountInfoContainer{
    background: #F0F0F0;
    padding: 20px;
    font-family: 'Mukta' !important;
    font-style: normal;
}
.accountInfoContainer p {
    margin-bottom: 6px !important;
}

.mainContactContainer, .contactPermissionContainer{
    padding: 0px 0px 20px;
    gap: 10px;
    background: #E9ECEF;
    border: 1px solid #CED4DA;
    margin-bottom: 20px;
}
.mainContactContainer p{
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    color: #000000;
}
.mainContactContainer span{
    font-weight: 400;
}
.mainContactNavbar, .serviceAddressNavbar{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    gap: 10px;
    height: 59px;
    background: #E84E3B;
}
.mainContactNavbarCP, .serviceAddressNavbarCP{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    gap: 10px;
    height: 59px;
    background: #306AC0;
}
.serviceAddressNavbar, .serviceAddressNavbarCP{
    margin-top: 40px;
}
.mainContactNavbar h2,.mainContactNavbarCP h2, .serviceAddressNavbar h2, .serviceAddressNavbarCP h2{
    font-weight: 500;
    font-size: 20px !important;
    line-height: 33px;
    text-align: center;
    color: #FFFFFF;
    margin: 0 !important;
}
.serviceAddressNavbar p, .serviceAddressNavbarCP p{
    margin-bottom: 0 !important;
    font-weight: 400;
    font-size: 16px !important;
    color: #FFFFFF;
}
.mainContactButton, .contactPermissionButton{
    padding: 10px 16px !important;
    width: 143px;
    height: 39px;
    background: #F8F9FA !important;
    border-radius: 4px !important;
    border: none !important;
    color: #343A40 !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 19px !important;
}
.mainContactButton:focus, .contactPermissionButton:focus{    
    box-shadow: none !important;
}
.row1, .row6, .row7{
    padding: 10px;
}

.row2, .row5, .row3, .row4, .row8{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* align-items: center; */
    width: 99%;
    padding: 10px;
}
.row8Col{
    width: 32%;
}

.row2Col, .row3Col, .row4Col, .row5Col{
    width: 49%;
}

.loadingDiv{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    background-color: rgba(26, 30, 40, 0.2);
    height:100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
} 
.addressInput{
    background: #FFFFFF;
    border: 1px solid #CED4DA;
    border-radius: 4px;
    height: 38px;
    width: 100%;
    padding: 0 10px;

}
.addressInput:focus-visible{
    outline: 2px solid #CED4DA !important;
}

.row1 .addressInput, .row6 .addressInput, .row7 .addressInput{
    width: 99%;
}
.row1 .addressInputError, .row6 .addressInputError, .row7 .addressInputError{
    width: 99%;
}

.addressInputError{
    background: #FFFFFF;
    border: 2px solid #DC3545;
    border-radius: 4px;
    width: 100%;
    height: 38px;
    padding: 0 10px;
}

.addressInputError:focus-visible{
    outline: 1px solid #DC3545 !important;

}
.horizontalLine{
    height:1px;
    color: #7D291F;
    background-color: #7D291F ;
}
.errorMessage{
    color: #DC3545 !important;
    font-size: 14px !important;
    font-weight: normal !important;
    margin: 0 !important;
}