.cookieContainer .modal-dialog .modal-content{
    background: transparent !important;
    border: none !important;
}
.homeContainer1{
    min-height: 596px;
    background: rgba(0, 0, 0, 0.3)  url('../../../Assets/Images/home_img1.jpg');
    background-size: cover;
    background-blend-mode: darken;
    padding: 30px;
}

.homeContainer1 h1{
    color: #ffffff;
    font-size: 4.2rem !important;
    font-weight: 900;
    padding-top: 14%;
}

.homeContainer1 h3{
    color: #ffffff;
    font-size: 2.3rem !important;
    font-weight: 800;
    padding-top: 4%;
}
.caButtonContainer{
    padding-top: 4%;
}

.homeContainer2{
    min-height: 408px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2% 16%;
}
.homeContainer2 h1{
    font-size: 2.8rem;
    font-weight: 800;
    text-align: center !important;
    color: #000 !important;
    padding-bottom: 30px;
}
.homeContainer3{
    min-height: 538px;
    background: rgba(0, 0, 0, 0.1)  url('../../../Assets/Images/home_img2.jpg');
    background-size: cover;
    background-blend-mode: darken;
    padding: 10px 30px;
}
.homeContainer3 .homeInnerContainer1{
    width: 55%;
}
.homeContainer3 h1{
    font-size: 4.2rem;
    font-weight: 800;
    color: #FFF !important;
    padding-top: 10%;
    padding-bottom: 30px;
    
}
.homeContainer3 .homeInnerContainer1 h3{
    font-size: 2.4rem;
    font-weight: 800;
    color: #FFF !important;
    padding-bottom: 30px;
}
.homeContainer4 h1{
    font-size: 3rem;
    font-weight: 700;
    color: #000 !important;
    text-align: center;
    padding-top: 8%;
    padding-bottom: 30px;

}
.homeContainer4InnerContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 40px;
}
.homeContainer4Column{
    width: 375px;
    text-align: center;
    font-size: 18px;
}
.homeContainer4Column .iconStyle{
    width: 133px;
    height: 133px;
    margin: auto;
    margin-bottom: 30px;
}
.homeContainerInnerpara1{
    font-size: 40px;
    font-weight: 800;
}
.homeContainerInnerpara2{
    font-size: 22px;
    font-weight: 600;
    padding: 10px 20px;
}
.getStartedButtonContainer{
    display: flex;
    justify-content: center;
    margin-bottom: 10%;
}
.homeContainer5 h2{
    font-size: 2.6rem;
    font-weight: 800;
    text-align: center;
    padding-bottom: 7%;
}
.carousalContainer{
     min-height: 500px;
}
.carousalContainer .carousel-inner{
    width: 70%;
    margin-left: 16%;
    padding-top: 7%;
    padding-bottom: 5%;
    min-height: 410px;

}
.carousalContainer .carousel-indicators{
    display: none;
}
.carousalContainer .carousel-caption{
    position: relative;
    left: 0%;
}

/*mobile view */
@media screen and (max-width: 576px) {
    .homeContainer1 h1{
        font-size: 3.2rem !important;
        font-weight: 800;
    }
    .modal-dialog{
        margin: auto!important;
    }
    
    .homeContainer1 h3{
        font-size: 1.8rem !important;
        font-weight: 800;
    }
    .homeContainer2 h1 {
        font-size: 2.3rem;
    }
    .homeContainer3 h1{
        font-size: 3.6rem;
        font-weight: 800;
        
    }
    .homeContainer3 .homeInnerContainer1{
        width: 100%;
    }
    .carousalContainer .carousel-inner{
        width: 70%;
        margin-left: 16%;
        padding-top: 16%;
        padding-bottom: 5%;
        min-height: 580px;
    
    }
    .homeContainer4InnerContainer{
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin-top: 40px;
    }
}
