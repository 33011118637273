
.pckgDetailsOuterContainer{
    background: #F0F0F0;
    padding: 20px;
    font-family: 'Mukta' !important;
    font-style: normal;
    min-height: 600px;
    padding-bottom: 80px;
}
.pckgDetailsInnerContainer{
    padding: 0px 0px 20px;
    gap: 10px;
    background: #E9ECEF;
    border: 1px solid #CED4DA;
    margin-bottom: 20px;
}
.pckgDetailsNav{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    gap: 10px;
    height: 59px;
    background: #E84E3B;
}
.pckgDetailsNavCP{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    gap: 10px;
    height: 59px;
    background: #306AC0;
}
.navBarTitle{
    font-weight: 500;
    font-size: 20px;
    line-height: 33px;
    text-align: center;
    color: #FFFFFF;
}
.row1{
    display: flex;
    justify-content: space-between;
    margin: 20px 20px 10px;
}
.row1 h4{
    font-weight: 500;
    font-size: 20px;
    line-height: 33px;
    margin: 0;
}
.row1 p{
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    margin: 0;
}
.row2, .row4, .row4CP{
    margin: 0 20px;
}
.row2 p{
    font-weight: 700;
    font-size: 17px;
    line-height: 28px;
}
.row2 span{
    font-weight: 500;
    font-size: 20px;
    line-height: 33px;
}
.serviceType{
    color: #17A2B8;
}
.row3{
    margin: 0 20px 10px;
    border-top: 1px solid #7D291F;
    border-bottom: 1px solid #7D291F;
}
.row3 h4{
    font-weight: 500;
    font-size: 20px;
    line-height: 33px;
}
.pckgDescriptionDiv{
    min-height: 160px;
}
.row3InnerContainer{
    width: 95%;
    border-top: 1px solid #7D291F;
    padding: 16px 0;
    
}
.speedDescription{
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.downloadSpeedDiv{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 126px;
    height: 106px;
    margin-right: 50px;
}
.uploadSpeedDiv{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 126px;
    height: 106px;
    margin-left: 50px;
}
.speedDescription img{
    height: 45px;
    width: 45px;
}
.speedDescription p{
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    text-decoration-line: underline;
    margin: 10px 0;

}
.speedLabel{
    font-weight: 500 !important;
    font-size: 20px !important;
    line-height: 33px !important;
    margin-top: 0 !important;
}
.row4 a{
    color: #E84E3B !important;

}
.row4CP a{
    color: #306AC0 !important;

}
.serviceStatusGreen{
    color: #28A745;
}
.serviceStatusRed{
    color: #DC3545;
}
.serviceStatusPending{
    color: #FF6007;
}
.loadingDiv{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    background-color: rgba(26, 30, 40, 0.2);
    height:100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}