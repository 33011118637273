.PaymentInfoOuterContainer{
    background: #F0F0F0;
    padding: 20px;
    font-family: 'Mukta' !important;
    font-style: normal;
    min-height: 600px;
    padding-bottom: 80px;
}
.paymentInfoContainer{
    padding: 0px 0px 20px;
    gap: 10px;
    background: #E9ECEF;
    border: 1px solid #CED4DA;
    margin-bottom: 20px;
}
.paymentMethodNav{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    gap: 10px;
    height: 59px;
    background: #E84E3B;
}
.paymentMethodNavCP{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    gap: 10px;
    height: 59px;
    background: #306AC0;
}
.navBarTitle{
    font-weight: 500;
    font-size: 20px;
    line-height: 33px;
    text-align: center;
    color: #FFFFFF;
}
.mainContactButton{
    padding: 10px 16px !important;
    width: 228px;
    height: 39px;
    background: #F8F9FA !important;
    border-radius: 4px !important;
    border: none !important;
    color: #343A40 !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 19px !important;
}
.mainContactButton:focus{    
    box-shadow: none !important;
}
.row1{
    margin: 16px 0 16px 20px;
    display: flex;
    flex-direction: row;
}
.paymentLabelBold{
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    color: #000000;
    margin-right: 16px;
    margin-bottom: 8px;
}
.row2{
    margin: 0 0 18px 20px;
}
.inputStyle1{
    width: 586px;
    height: 38px;
    border: 1px solid #CED4DA;
    border-radius: 4px; 
    padding: 0 10px;
}
.row3, .row4{
    margin: 0 0 18px 20px;
    display: flex;
    flex-direction: row;
}
.row3Col1{
    width: 380px;
    margin-right: 10px;
}
.row3Col2{
    width: 196px;
}
.inputStyle2{
    width: 100%;
    height: 38px;
    border: 1px solid #CED4DA;
    border-radius: 4px; 
    padding: 0 10px;
}
.row4Col1{
    width: 288px;
    margin-right: 10px;
}
.row4Col2{
    width: 288px;
}
.row5, .row6, .row7{
    margin: 0 0 18px 20px;
    width: 940px;
}
.row7{
    display: flex;
    flex-direction: row;
    margin: 0 0 18px 20px;
    width: 940px;
}
.row7Col1, .row7Col2{
    width: 310px;
    margin-right: 10px;
}
.row7Col3{
    width: 300px;
}
.optionalLabel{
    font-weight: 400 !important;
}
.row8{
    margin: 40px 0 18px 20px;
}
.paymentStatusPara{
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
}
.paymentMethodLink{
    color: #E84E3B;
    cursor: pointer;
    text-decoration: underline;
}
.updateRow1{
    display: flex;
    flex-direction: row;
    margin: 18px 0 0 20px;
}
.row9{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 30px 0 25px 0;
}

.row9Para{
    width: 90%;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    margin-left: 20px;
    margin-bottom: 0px;
}
.errorPara{
    font-family: 'Inter';
    font-style: normal;
    color: #DC3545;
}
.updateDataButton{
    padding: 10px 16px !important;
    width: 143px;
    height: 39px;
    background: #E84E3B !important;
    border: none !important;
    border-radius: 4px !important;
    color: #FFFFFF !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 19px !important;
    text-align: center !important;
    margin-left: 20px;
}
.updateDataButtonCP{
    padding: 10px 16px !important;
    width: 143px;
    height: 39px;
    background: #306AC0 !important;
    border: none !important;
    border-radius: 4px !important;
    color: #FFFFFF !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 19px !important;
    text-align: center !important;
    margin-left: 20px;
}
.updateDataButton:focus, .updateDataButtonCP:focus{
    box-shadow: none !important;
}

.errorMessage{
    color: #DC3545 !important;
}
.inputError{
    border: 1px solid #DC3545 !important;
}
.inputError:focus-visible{
    outline: 1px solid #DC3545 !important;
}
.autoPayActive{
    color: #28A745;
}
.autoPayInactive{
    color: #DC3545;
}
.horizontalLine{
    height:1px;
    color: #7D291F;
    background-color: #7D291F ;
}
.loadingDiv{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    background-color: rgba(26, 30, 40, 0.2);
    height:100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.confirmCheckError{
    outline: #DC3545;
    border: 2px solid #DC3545 !important;
}