.inputStyle{
    border: 1px solid #CED4DA !important;
    border-radius: 4px !important;
    height: 38px;
    width: 420px !important;
}
.inputError{
    border: 1px solid #DC3545 !important;
    border-radius: 4px !important;
    height: 38px;
}
.inputError:focus-visible{
    outline: 1px solid #DC3545 !important;
}
.inputErrorexpiry{
    border: 1px solid #DC3545 !important;
    border-radius: 4px !important;
    height: 38px;
    width: 270px !important;
    padding: 1px 10px !important;
}
.inputErrorexpiry:focus-visible{
    outline: 1px solid #DC3545 !important;
}
.inputErrorcvv{
    border: 1px solid #DC3545 !important;
    border-radius: 4px !important;
    height: 38px;
    width: 135px !important;
    padding: 1px 10px !important;
}
.inputErrorcvv:focus-visible{
    outline: 1px solid #DC3545 !important;
}
.cardExpiry{
    width: 270px !important;
    height: 38px;
    border: 1px solid #CED4DA;
    border-radius: 4px;
    padding: 1px 10px !important;
}
.cvvInput{
    width: 135px !important;
    height: 38px;
    border: 1px solid #CED4DA;
    border-radius: 4px;
    padding: 1px 10px !important;
}
.expiryCvvContainer{
    display: flex;
    justify-content: space-between;
    padding: 0 !important;
}
.expiryDiv{
    flex: 2;
}
.cvvDiv{
    flex: 1;
}
.cardPaymentContainer label{
    padding: 0;
}
.billingTitle{
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    padding: 0 !important;
}
.checkBoxContainer{
    display: flex;
    flex-direction: row;  
}
.errorMessage{
    color: #DC3545 !important;
}
.cancelButton{
    align-items: center;
    padding: 15px;
    gap: 10px;
    width: 217px;
    height: 57px;
    background: #FFFFFF !important;
    border: 2px solid #E84E3B !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    line-height: 27px;
    color: #E84E3B !important ;
    border-radius: 0 !important;
}
.cancelButton:focus{
    box-shadow: none !important;
}
.submitButton{
    align-items: center;
    padding: 15px;
    gap: 10px;
    width: 217px;
    height: 57px;
    background: #E84E3B !important;
    border: 2px solid #E84E3B !important;
    border-radius: 0 !important;
}
.submitButton:focus{
    box-shadow: none !important;
}
.buttonContainer{
    display: flex;
    justify-content: space-between;
    width: 450px !important;
    margin: 30px 0px 30px -28px;
}
.errorWarning{
    color: #DC3545;
    width: 90%;
    margin: auto;
    text-align: center;
}
.errorWarning p{
    margin: 0 !important;
}

/*mobile view */
@media screen and (max-width: 576px) {
    .inputStyle{
        width: 320px !important;
    }
    .cardExpiry{
        width: 240px !important;
        margin-right: 10px;
    }
    .cvvInput{
        width: 70px !important;
    }
    .inputErrorexpiry{        
        width: 240px !important;
        margin-right: 10px;

    }
    .inputErrorcvv{
        width: 70px !important;
    }
    .buttonContainer{
        display: flex;
        justify-content: space-between;
        width: 340px !important;
        margin: 30px 10px 30px -20px;
    }
    .cancelButton{
        width: 150px;
        
    }
    .submitButton{
        width: 160px;
    }
    .inputError{
        width: 320px !important;
    }
}