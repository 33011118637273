.aboutUsContainer{
    background-color: #FFFFFF;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    min-height: 320px;
    padding: 20px;
}
/* .aboutTitle{
    text-align: center;
    font-size: 24px;
    background: -webkit-linear-gradient(#A2D934, #45A427);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
} */