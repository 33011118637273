.navigationBar{
    display: flex;
    align-items: center;
    padding: 0 20px !important;
    background-color: #FFF !important
}
.navigationBar a{
    text-decoration: none;
}
.navbarContainer{
    display: flex;
    align-items:flex-start;
 }
.navMenubar{
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
}
.navbarLink{
    width: 83px;
    height: 16px;
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 16px;
    padding-top: 10px;
    cursor: pointer;
    color: #E84E3B !important;
    text-decoration-line: underline;
    text-align: center;

}  
.welcomeText{
    padding-top: 10px;
    height: 16px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #7D291F;
    margin-right: 15px;

}

.dividerBar{
    width: 6px;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #7D291F;
    padding-top: 10px;
}
.brandLogo{
    width: 196px;
    height: 102px;
}
.mobileLogo{
    width: 10px;
    height: 10px;
    margin-left: 14%;
    display: none;
}
@media screen and (max-width: 860px) {
    .navigationBar{
        padding: 0 !important;
    }
    .navMenubar{
        width: 94%;
        align-items: flex-start;
    }
    .navbarLink{
        width: 96px;
        text-align: left;
    }
    .brandLogo{
        width: 196px;
        height: 102px;
    }
    .dividerBar{
        display: none;
    }
    .welcomeText{
        display: none;
    }
}
/*mobile view */
@media screen and (max-width: 767px) {
    .brandLogo{ 
        margin: 0;
        width: 4px;
        height: 4px;    
        display: none;
    }
    .mobileLogo{
        width: 55px;
        height: 55px;
        display: flex;
        align-items: center;
    }
    .navigationBar{
        display: flex;
        flex-direction: column;
        padding: 0;
        margin-bottom: 0;
        list-style: none;
        min-height: 60px;
        justify-content: flex-end;
    }
    .navbarLink{
        width: 100%;
        text-align: left;
        font-size: 14px !important;
        line-height: 18px;
        gap: 14px;
        margin: 0 !important;
    }  
}