.toastContainer{
    width: 350px !important;
    padding: 0 !important;
    background-color: #FFF !important;
}
.toastHeader{
    height: 28px;
    color: #FFF !important;
    font-weight: 700;
    font-size: 17px;
}
.successBg{
    background-color: #28A745 !important;
}
.errorBg{
    background-color: #DC3545 !important;
}
.toastContainer .btn-close{
    background-image: url("../../Assets/Images/x-circle.png") !important;
}
.toastBody{
    display: flex !important;
    flex-direction: row !important;
    align-items: flex-start !important;
    padding: 5px 10px !important;
    font-size: 16px !important;
}
.successBody{
    min-height: 50px;
}
.errorBody{
    min-height: 66px !important;
}