/* .dropdown:hover>.dropdown-menu{
    display: block !important;
} */
.dropdown-toggle{
    margin-top: -8px;
    width: 106px;
    height: 28px;
    text-align: center;
    color: #000000 !important;
    font-family: 'Mukta';
    font-style: normal;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 28px;
    padding-top: 10px;
}
.dropdown-menu{
    margin-top: 14px !important;
}
.dropdown-item:hover{
    background-color: #dd6c5e59 !important;
}
.dropdown-item:active{
    background-color: #e84e3b59 !important;
}
.nav-link{
    text-align: left !important;
}