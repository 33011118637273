
.statementOuterContainer{
    background: #F0F0F0;
    padding: 20px;
    font-family: 'Mukta' !important;
    font-style: normal;
    min-height: 600px;
    padding-bottom: 80px;
}
.statementInnerContainer{
    padding: 0px 0px 20px;
    gap: 10px;
    background: #E9ECEF;
    border: 1px solid #CED4DA;
    margin-bottom: 20px;
}
.statementCenterNav{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    gap: 10px;
    height: 59px;
    margin-bottom: 10px;
    background: #E84E3B;
}
.statementCenterNavCP{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    gap: 10px;
    height: 59px;
    margin-bottom: 10px;
    background: #306AC0;
}
.navBarTitle{
    font-weight: 500;
    font-size: 20px;
    line-height: 33px;
    text-align: center;
    color: #FFFFFF;
}
.loadingDiv{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    background-color: rgba(39, 42, 49, 0.2);
    height:100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.loadingMessage{
    width: 100%;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    color: #FFFFFF;
}
.downloadIcon{
    cursor: pointer;
}