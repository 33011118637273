.contactFooter{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px 30px;
    gap: 10px;
    height: 292px;
    font-family: 'Mukta';
    font-style: normal;
    font-weight: 600;
}
.is-dark{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px 30px;
    gap: 10px;
    height: 292px;
    background-color: #000 !important;
    color: #fff;
}
.is-light{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px 30px;
    gap: 10px;
    height: 292px;
    background-color: #fff !important;
}
.contactFooterRow1{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 982px;
    font-size: 32px;
    line-height: 53px;
}
.contactFooterInnerRow1{
    display: flex;
    flex-direction: row;
}
.contactFooterCol1{
    text-align: center;
}
.contactFooterCol2{
    text-align: center;
}
.contactFooterRow2{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-around;
    padding: 0px;
    gap: 30px;
    height: 78px;
    width: 780px;
    font-size: 24px;
    line-height: 40px;
    text-align: center;
}
.phoneNumberContainer{
    font-weight: 700;
    font-size: 17px;
    line-height: 28px;
    height: 34px;
}
.emailContainer{
    height: 34px;
}
.emailContainer a{
    font-weight: 700;
    font-size: 17px;
    color: #212529;
}
.synkroEmailLink:hover{
    color: #E84E3B;
}

.contactButton-Bg{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 217px !important;
    height: 58px;
    background: #FFF;
}
.contactButton-Bg a{
    text-decoration: none;
    color: #120402;
    width: 100%;
    font-weight: 700;
    font-size: 17px;
    line-height: 28px;
    text-align: center;
}
.contactButton-Bg a:hover{
    color: #120402;
}
.contactButton-Lg{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 217px !important;
    height: 58px;
    background: #120402;
}
.contactButton-Lg a{
    text-decoration: none;
    color: #FFF;
    width: 100%;
    font-weight: 700;
    font-size: 17px;
    line-height: 28px;
    text-align: center;
}
.contactButton-Lg a:hover{
    color: #FFF;
}


/*mobile view */
@media screen and (max-width: 576px) {
    .contactFooter{
        height: 380px; 
    }
    .is-dark{
        height: 380px; 
    }
    .is-light{
        height: 380px;
    }
    .contactFooterRow1{
        font-weight: 600;
        width: 360px;
        height: 106px;
    }
    .contactFooterRow2{
        gap: 10px;
        height: 132px;
        width: 380px;
        font-size: 24px;
        line-height: 20px;
        text-align: center;
    }
}