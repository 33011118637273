.loaderContainer{
    min-height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.outerContainer{
    font-family: 'Mukta';
    font-style: normal;
}
.innerContainer1{
    display: flex;
    flex-direction: row;
    padding: 160px 0px;
    gap: 10px;
    width: 100%;
    height: 376px;
    background: linear-gradient(0deg, rgba(240, 240, 240, 0.2), rgba(240, 240, 240, 0.2)), 
    url(../../../Assets/Images/CustomHome_Hero.jpg);
    background-size: cover;
}
.container1Div1{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px 30px 15px;
    width: 48%;
    height: 150px;
    background: #120402;
}
.container1Div1 h4{
    font-weight: 700 !important;
    font-size: 64px !important;
    line-height: 106px;
    letter-spacing: -0.02em;
    color: #E84E3B;
}
.container1Div1 p{
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    text-align: right !important;
    color: #E84E3B;
}
.customContainer1Div1{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px 30px 15px;
    width: 48%;
    height: 150px;
    background: #120402;
}
.customContainer1Div1 h4{
    font-weight: 700 !important;
    font-size: 64px !important;
    line-height: 106px;
    letter-spacing: -0.02em;
    color: #306AC0;
}
.customContainer1Div1 p{
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    width: 100%;
    text-align: right !important;
    color: #306AC0;
}
.innerContainer2{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 0px;
    gap: 30px;
    min-height: 1458px;
    background: #F0F0F0;
}
.para1{
    width: 80%;
    margin: 0 auto ;
    font-weight: 800;
    font-size: 20px;
    line-height: 33px;
    text-align: center;
    color: #000000;
}
.para2{
    height: 28px;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    color: #152536;
    margin: 0 auto;

}
.para2 a{
    text-decoration: underline;
    color: #E84E3B;
}
.para2 a:hover {
    color: #e01e00;
}
.customPara2{
    height: 28px;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    color: #152536;
    margin: 0 auto;

}
.customPara2 a{
    text-decoration: underline;
    color: #306AC0;
}
.customPara2 a:hover {
    color: #003d99;
}
.signupButtonContainer{
    width: 217px;
    height: 58px;
}
.borderedMiddleContainer{
    width: 80%;
    min-height: 408px;
    border-top: 1px solid #9EA0A6;
    border-bottom: 1px solid #9EA0A6;
    padding: 20px 0;

}
.innerDiv1{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 30px;
    min-height: 388px;
}
.innerRow1{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    gap: 50px;
    height: 164px;
}
.innerCol1{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 431px;
}
.ellipseImage{
    width: 75px;
    height: 75px;
}
.columnTitle{
    font-weight: 700 !important;
    font-size: 20px !important;
    line-height: 33px;
    text-align: center;
    color: #E84E3B ;
    margin: 0 !important;
}
.customColumnTitle{
    font-weight: 700 !important;
    font-size: 20px !important;
    line-height: 33px;
    text-align: center;
    color: #306AC0 ;
    margin: 0 !important;
}
.innerCol1 p{
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    color: #000000;
    margin-bottom: 0 !important;
}
.thirdContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    width: 80%;
    min-height: 445px;
    margin: 20px auto;
    gap: 10px;
}
.innerCol2{
    font-weight: 700;
    font-size: 20px;
    line-height: 33px;
    display: flex;
    align-items: center;
    width: 451px;
    height: 437px;
    text-align: center;
    color: #000000;
}
.innerCol3{
    width: 451px;
    height: 437px;
    background: url(../../../Assets/Images/CustomHome_Frame81.png);
}
.bottomContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 38px;
    width: 80%;
    min-height: 233px;
    border-top: 1px solid #9EA0A6;
    margin: 10px auto;
    padding: 20px 0;
}
.bottomContainer h5{
    font-weight: 800;
    font-size: 20px;
    line-height: 33px;
    text-align: center;
    color: #000000;
}
.bottomContainer p{
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    color: #000000;
}
.optimizeButtonContainer{
    width: 217px;
    height: 58px;
}

/* @media screen and (min-width: 1601px) {
    .innerContainer1{
        height: 520px;
    }
} */