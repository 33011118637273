.progressContainer{
    width: 60% !important;
    margin: auto;
}
.progressBarInner {
    background-color: #e84e3b;
    border-radius: 4px;
    transition: width 1s ease-in-out;
    height: 20px;
  }
  
  .progressBarInner:hover {
    width: 76%;
  }
  .progressLabel {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .progressBarBorder{
    background-color: #D9D9D9;
    border-radius: 4px;
  }
  .activeLabel{
    font-weight: 800;
    width: 33%;
  }
  .indvidualLabels{
    width: 33%;
  }
  .indvidualLabelsfinal{
    width: 33%;
    text-align: right;
  }
  .activeLabelfinal{
    font-weight: 800;
    width: 33%;
    text-align: right;
  }
  .indvidualLabelsCenter{
    width: 33%;
    text-align: center;
  }
  .activeLabelCenter{
    font-weight: 800;
    width: 33%;
    text-align: center;
  }
  
  
/*mobile view */
@media screen and (max-width: 576px) {
  .progressContainer{
    width: 330px !important;
}
.progressBarInner {
    height: 16px;
  }
  
  .progressBarInner:hover {
    width: 80%;
  }
  .progressLabel {
    font-size: 12px;
    line-height: 20px;
  }
  .progressBarBorder{
    background-color: #D9D9D9;
    border-radius: 4px;
  }
 
}