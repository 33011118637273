.bulletPoints{
    padding-left: 30px;
}
.trdLayoutContainer{
    background-color: #FFFFFF;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    min-height: 680px;
    padding: 20px;
}
.trdTitle{
    text-align: center;
    
}
.bulletLinks{
    font-size: 14px;
    font-weight: normal;
    text-decoration: none;
}
.trdLayoutContainer a{
    text-decoration: none;
    font-size: 18px;
}