.buttonStyle{
    width: 100% !important;
    height: 58px;
    border: none !important;
    background: #306AC0 !important;
    border-radius: 99px !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    letter-spacing: .08em !important;
    color: #120402 !important;
}
.buttonStyle:hover{
    background-color: #003d99 !important;
}
.buttonStyle:focus{    
    box-shadow: none !important;
}