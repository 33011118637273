
.outerContainer{
    background-color: #FFF;
    padding-top: 20px;
}
.topContainer{
    height: 480px;
    width: 100%;
    background: rgba(0, 0, 0, 0.3) url("../../../Assets/Images/residentialImage-1.jpg");
    background-blend-mode: darken;
    background-size: cover;
    padding-left: 40px;
    padding-top: 14%;
    color: #F0F0F0;
}
.topContainer h1{
    font-weight:900 !important;
    font-size: 72px !important;
    width: 50%;
}
.topContainer h5{
    font-weight:700 !important;
    font-size: 28px !important;
    margin-top: 40px;
}
.centerTitle{
    text-align: center;
    font-weight: 700 !important;
    font-size: 48px ;
    padding: 80px 10px 40px 10px;
}
.middleContainer{
    background-color: #FFF;
}
.midInnerContainer{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 20px 30px 80px 30px;
}
.innerColumns{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.roundImageContainer img{
    border-radius: 100%;
    width: 272px;
    height: 272px;
}
.imageTitle{
    font-weight: 800;
    text-align: center;
    font-size: 40px;
    padding: 30px 10px 10px 10px;
}
.imageDescription{
    width: 340px;
    padding: 10px;
    text-align: center;
    font-size: 1.3rem;
}
.bottomContainer{
    background-color: #F0F0F0;
    padding: 40px 60px 80px 60px;
}
.bottomContainer h1{
    font-size: 46px;
    font-weight: 800;
}
.bottomInnerDiv{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 48px;
}
.bottomInnerCol{
    width: 380px;
    padding: 20px;
    font-size: 1.1rem;
}

.bottomInnerDiv h2{
    font-size: 38px;
    font-weight: 800;
    margin-bottom: 28px;
}
.buttonContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.disclaimerText{
    margin-left: 30px;
}
.disclaimerText p{
    margin-bottom: 0;
}

@media screen and (max-width: 860px) {
    
    .midInnerContainer{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px 20px 50px 20px;
    }
    .bottomContainer h1{
        text-align: center;
    }
    .bottomInnerDiv{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 20px;
    }
    .buttonContainer{
        display: flex;
        justify-content: center;
    }
    
}

/*mobile view */
@media screen and (max-width: 576px) {
    .outerContainer{
        padding-top: 0;
    }
    .topContainer{
        padding-left: 20px;
        padding-top: 0;
    }
    .topContainer h1{
        font-weight:900 !important;
        font-size: 56px !important;
        width: 100%;
        padding-top: 40px;
    }
    .topContainer h5{
        font-weight:700 !important;
        font-size: 28px !important;
        margin-top: 20px;
    }
    .innerColumns{
        /* width: 370px; */
    }
    .bottomInnerDiv h2{
        font-size: 32px;
        font-weight: 700;
        margin-bottom: 10px;
    }
    .bottomInnerCol{
        padding: 0;
        margin-bottom: 20px;
        width: 100%;
    }
    .bottomContainer{
        /* width: 370px !important; */
        padding: 30px;
    }
    .bottomContainer h1{
        font-size: 32px;
        font-weight: 700;
    }
    .bottomInnerDiv{
        width: 100%;
    }
    .bottomInnerCol h2{
        font-size: 30px;
        font-weight: 700;
    }
}