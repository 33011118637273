.sidebarNav {
    background: #000000;
    width: 315px;
    /* height: 847px; */
    min-height: calc(100vh - 120px);
    display: flex;
    justify-content: center;
    position: fixed;
    top: 120px;
    left: 0;
    transition: 350ms;
    z-index: 10;
    font-size: 16px !important;
    margin: 0 !important;
  }

.sidebarWrap{
    width: 100%;
}
.sidebarLink, .sidebarLinkCP{
    display: flex;
    color: #e1e9fc;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    list-style: none;
    height: 60px;
    text-decoration: none;
    font-size: 18px;
    border-top: 1px solid #FFF;
}
.sidebarLink:hover {
    background: #000000;
    color: #E84E3B !important;
    cursor: pointer;
}
.sidebarLinkCP:hover {
    background: #000000;
    color: #306AC0 !important;
    cursor: pointer;
}
.activeSidebarLink{
    display: flex;
    color: #e1e9fc;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    list-style: none;
    height: 60px;
    text-decoration: none;
    font-size: 18px;
    border-top: 1px solid #FFF;
    border-left: 4px solid #E84E3B;
}
.activeSidebarLink:hover {
    background: #000000;
    color: #E84E3B !important;
    cursor: pointer;
}
.activeSidebarLinkCP{
    display: flex;
    color: #e1e9fc;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    list-style: none;
    height: 60px;
    text-decoration: none;
    font-size: 18px;
    border-top: 1px solid #FFF;
    border-left: 4px solid #306AC0;
}
.activeSidebarLinkCP:hover {
    background: #000000;
    color: #306AC0 !important;
    cursor: pointer;
}
.dropdownLink, .dropdownLinkCP{
    background: #000000;
    height: 60px;
    padding-left: 3rem;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #f5f5f5;
    font-size: 18px;
}
.dropdownLink:hover {
    cursor: pointer;
    color: #E84E3B !important;
}
.dropdownLinkCP:hover {
    cursor: pointer;
    color: #306AC0 !important;
}
/* .dropdownLink:focus-within {
    border-left: 4px solid #E84E3B;
    cursor: pointer;
}
.dropdownLink:visited {
    border-left: 4px solid #E84E3B;
    cursor: pointer;
}
.dropdownLink:target {
    border-left: 4px solid #E84E3B;
    cursor: pointer;
} */
.activeDropdownLink{
    background: #000000;
    height: 60px;
    padding-left: 3rem;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #f5f5f5;
    font-size: 18px;
    border-left: 4px solid #E84E3B;
}
.activeDropdownLink:hover {
    cursor: pointer;
    color: #E84E3B !important;
}
.activeDropdownLinkCP{
    background: #000000;
    height: 60px;
    padding-left: 3rem;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #f5f5f5;
    font-size: 18px;
    border-left: 4px solid #306AC0;
}
.activeDropdownLinkCP:hover {
    cursor: pointer;
    color: #306AC0 !important;
}
.sidebarLabel{
    margin-left: 16px;
}
.accountStatusContainer{
    height: 140px;
    padding: 20px 20px;
}
.accountStatus{
    display: flex;
    flex-direction: row;
}
.accountStatus img {
    width: 24px;
    height: 24px;
}
.accountStatus p {
    margin-left: 10px;
    font-size: 16px;
    line-height: 28px;
    color: #DEE2E6;
}
.successMsg{
    color: #28A745;
}
.pendingMsg{
    color: #FF6007;
}
.discontinuedMsg, .dueMsg{
    color:#DC3545;
}
.titleParagraph{
    width: 280px;
    font-family: 'Mukta';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #DEE2E6;
    margin: auto;

}