.alertbar{
    background-color: #120402;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0px;
    /* gap: 10px; */
    height: 58px;
}

.alertbarContent{
    height: 28px;
    font-family: 'Mukta';
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 28px;
    text-align: center;

}
.alertbarContent a{
    text-decoration: none !important;
    color: #FFFFFF !important;
}
.closeIconCol{
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.closeIcon{
    width: 14px;
    height: 14px;
}

@media screen and (max-width: 768px) {
    .alertbarContent{
        width: 300px;
    }
    .closeIconCol{
        width: 20px;
        margin-right: 10px;
        cursor: pointer;
    }
    .alertbar{
        width: 100% !important;
    }
}