.navigationBar{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 10px !important;
    position: relative;
    background-color: #FFF !important
    /* height: 87px; */
}
.navigationBar a{
    text-decoration: none;
}
.navbarContainer{
    display: flex;
    align-items:flex-start;
    background-color: #fff;
    /* flex-direction: column;
    justify-content: center;
    align-items: flex-end;*/
    /* min-height: 48px;  */
 }
.navMenubar{
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-end;
    padding-top: 18px;
}
 .checkButton{
    text-align: center;
    width: 216px;
    height: 48px;
    background-color: #000;
    color: #fff !important;
    padding: 12px !important;   
    text-decoration: none; 
    cursor: pointer;
}
.navbarLink{
    width: 106px;
    height: 28px;
    text-align: center;
    color: #000000 !important;
    font-family: 'Mukta';
    font-style: normal;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 28px;
    padding-top: 10px;
    cursor: pointer;
} 
.navbarLink2{
    width: 106px;
    height: 28px;
    text-align: center;
    color: #000000 !important;
    font-family: 'Mukta';
    font-style: normal;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 28px;
    padding-top: 10px;
    cursor: pointer;
    margin-right: 5px;
}  
.navbarLink a{
    text-decoration: none;
    color: #000 !important;
}
.navbarLink2 a{
    text-decoration: none;
    color: #000 !important;
}
.userNameText{
    width: 186px;
    height: 32px;
    text-align: center;
    color: #000000 !important;
    font-family: 'Mukta';
    font-style: normal;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 28px;
    padding-top: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.brandLogo{
    width: 196px;
    height: 102px;
    cursor: pointer;
    /* margin-left: 6%; */
}
.mobileLogo{
    width: 10px;
    height: 10px;
    margin-left: 14%;
    display: none;
}
@media screen and (max-width: 860px) {
    .navigationBar{
        padding: 0 !important;
    }
    .navMenubar{
        width: 94%
    }
    .navbarLink{
        width: 96px;
    }
    .navbarLink2{
        width: 96px;
    }
    .loginLink{
        width: 120px;
    }
    .brandLogo{
        width: 196px;
        height: 102px;
    }
    .checkButton{
        width: 180px;
    }
}
/*mobile view */
@media screen and (max-width: 767px) {
    .brandLogo{ 
        margin: 0;
        width: 4px;
        height: 4px;    
        display: none;
    }
    .mobileLogo{
        width: 55px;
        height: 55px;
        display: flex;
        align-items: center;
    }
    .navigationBar{
        display: flex;
        flex-direction: column;
        padding: 0;
        margin-bottom: 0;
        list-style: none;
        min-height: 60px;
        justify-content: flex-end;
    }
    .checkButton{
        text-align: center;
        width: 110px;
        height: 32px;
        padding: 6px 0 !important; 
        margin-top: 12px;
        font-size: 14px;
    }
    .navbarLink{
        width: 100%;
        text-align: left;
        font-size: 14px !important;
        line-height: 18px;
        gap: 14px;
        margin: 0 !important;
    } 
    .navbarLink2{
        width: 100%;
        text-align: left;
        font-size: 14px !important;
        line-height: 18px;
        gap: 14px;
        margin: 0 !important;
    }  
    .userNameText{
        display: none;
    }
}