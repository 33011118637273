.outerContainer{
    background-color: #F0F0F0;
    padding: 30px;
}
.signupTitle{
    height: 66px;
    font-weight: 700;
    font-size: 40px;
    line-height: 66px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #152536;
}
.para1{
    min-height: 47px;
    font-weight: 700;
    font-size: 28px;
    line-height: 47px;
    text-align: center;
    color: #152536;
}
.para2{
    min-height: 28px;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    color: #152536;
}
.para2 span{
    color: #E84E3B;
    cursor: pointer;
}
.para2 span:hover {
    color: #ff7c67;
}
.formTitle{
    margin-top: 15px;
    font-weight: 700;
    font-size: 28px;
    line-height: 47px;
    text-align: center;
    color: #152536;
}
.reviewContainer{
    font-family: 'Mukta';
    font-style: normal;
    width: 420px;
    display: flex;
    flex-direction: column;
    margin: auto;  
    margin-top: 30px; 
    padding: 4px;
}
.innerContainer1{
    min-height: 120px;
    gap: 5px;
    margin-bottom: 10px;
}
.innerContainer2{
    min-height: 120px;
    gap: 5px;
    margin-bottom: 10px;

}
.innerContainer3{
    min-height: 120px;
    gap: 5px;
    margin-bottom: 10px;
}
.innerContainer4{
    min-height: 220px;
    gap: 5px;
    margin-bottom: 10px;
}
.innerContainer5{
    min-height: 100px;
    gap: 5px;
    margin-bottom: 10px;
}
.cancelButton{
    align-items: center;
    padding: 15px;
    gap: 10px;
    width: 217px;
    height: 57px;
    background: #FFFFFF !important;
    border: 2px solid #E84E3B !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    line-height: 27px;
    color: #E84E3B !important ;
    border-radius: 0 !important;
}
.cancelButton:focus{
    box-shadow: none !important;
}
.submitButton{
    align-items: center;
    padding: 15px;
    gap: 10px;
    width: 217px;
    height: 57px;
    background: #E84E3B !important;
    border: 2px solid #E84E3B !important;
    border-radius: 0 !important;
}
.submitButton:focus{
    box-shadow: none !important;
}
.buttonContainer{
    display: flex;
    justify-content: center;
    width: 450px !important;
}
.signupButton{
    width: 217px;
    height: 58px;
    background: #E84E3B !important;
    font-weight: 700;
    font-size: 17px;
    border-radius: 0 !important;
    border: none !important;

}
.signupButton:focus{
    box-shadow: none !important;
}
.innerRow{
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
}
.leftCol{
    display: flex;
    justify-content: flex-start;
    width: 50%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.rightCol{
    display: flex;
    justify-content: flex-end;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.rightCol1{
    display: flex;
    justify-content: flex-start;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 50%;
}
.sectionTitle{
    font-weight: 700;
    font-size: 16px;
    line-height: 27px;
    color: #152536;
}
.editLink{    
    font-weight: 700;
    font-size: 16px;
    line-height: 27px;
    text-decoration-line: underline;
    color: #E84E3B;
    cursor: pointer;
}

/*mobile view */
@media screen and (max-width: 576px) {
    .outerContainer{
        gap: 6px;
    }
    .signupTitle{
        height: 33px;
        font-size: 20px;
        line-height: 33px;
    }
    .para1{
        height: 20px;
        font-size: 16px;
        line-height: 20px;
    }
    .para2{
        height: 20px;
        font-size: 12px;
        line-height: 20px;
    }
    .reviewContainer{
        width: 330px !important;
        gap: 6px;
    }
}