.termsMainContainer{
    background-color: #F0F0F0;
}
.termsMainContainer .para1{
    font-weight: 700;
    font-size: 28px;
    line-height: 47px;
    text-align: center;
    color: #152536;
}
.termsMainContainer .para2{
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    color: #152536;
}
.termsMainContainer .signupTitle{
    min-height: 66px;
    font-weight: 700;
    font-size: 40px;
    line-height: 66px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #152536;
}
.termsMainContainer .termsAccordionContainer{
    width: 80%;
    margin: 30px auto;
}

.termsAccordionContainer .accordion-button{
    height: 59px;
    background: #120402 !important;
    /* border: 1px solid #CED4DA; */
    /* border-radius: 4px 4px 0px 0px; */
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 27px;
    color: #FFFFFF !important;
}
.termsAccordionContainer .accordion-button:focus{
    box-shadow: none !important;
}
.termsAccordionContainer .accordion-header{
    border-radius: 4px 4px 0px 0px !important;
    border: 1px solid #CED4DA;

}
.termsAccordionContainer .accordion-button::after{
    background-image: url("../../../Assets/Images/chevron-down.svg") !important;
}
.termsAccordionContainer .accordion-button .collapsed::after{
    background-image: url("../../../Assets/Images/chevron-up.svg") !important;
}
.termsHeader{
    margin: 0 0 0 10px;
}
.termsButtonContainer{
    width: 80%;
    text-align: center;
    padding: 20px;
    margin: auto;
}
.termsButtonContainer input{
    margin-right: 6px;
    margin-top: 1px;
}

.termsCancelButton{
    align-items: center;
    padding: 15px;
    gap: 10px;
    width: 217px;
    height: 57px;
    background: #FFFFFF !important;
    border: 2px solid #E84E3B !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    line-height: 27px;
    color: #E84E3B !important ;
    border-radius: 0 !important;
    margin-right: 10px;
}
.termsCancelButton:focus{
    box-shadow: none !important;
}
.termsNextButton{
    align-items: center;
    padding: 15px;
    gap: 10px;
    width: 217px;
    height: 57px;
    background: #E84E3B !important;
    border: 2px solid #E84E3B !important;
    border-radius: 0 !important;
}
.termsNextButton:focus{
    box-shadow: none !important;
}
.disclosureLink{
    color: #E84E3B !important;
    text-decoration: none;
    cursor: pointer;
}

/*mobile view */
@media screen and (max-width: 576px) {
    .termsMainContainer .termsAccordionContainer{
        width: 95%;
        margin: 30px auto;
    }
    .termsButtonContainer{
        width: 100%;
        padding: 20px;
        margin: 0;
    }
    .termsButtonContainer input{
        text-align: left;
    }
    .termsCancelButton{
        margin-bottom: 10px;
        width: 292px;
    }
    .termsNextButton{
        width: 292px;
    }
}