.forgotPwdModalContainer{
    font-family: 'Mukta';
    font-style: normal;
}
.forgotPwdModal{
    display: flex !important;
    justify-content: center;
    align-items: center;
    width: 100% !important;
}
.forgotPwdModal .modal-dialog .modal-content{    
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25) !important;
    border-radius: 4px !important;
    padding: 10px 24px 20px 24px !important;
    width: 498px !important;
}
.forgotPwdModal .modal-header{
    border-bottom: none !important;
}
.btn-close{
    box-shadow: none !important;
}