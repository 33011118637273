.buttonStyle{
    width: 100% !important;
    height: 58px;
    border: none !important;
    background: #E84E3B !important;
    border-radius: 99px !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    letter-spacing: .08em !important;
    color: #120402 !important;
}
.buttonStyle:hover{
    background-color: #eb0000 !important;
}
.buttonStyle:focus{    
    box-shadow: none !important;
}