.navigationBar{
    display: flex !important;
    flex-direction: column;
    align-items: center;
    position: fixed !important;
    z-index: 10;
    top: 0;
    background-color: #FFF !important;
    height: 87px;
    width: 100%;
}
.navigationBar a{
    text-decoration: none;
}
.loginNavbar{
    display: flex !important;
    flex-direction: column;
    align-items: center;
    position: relative !important;
    background-color: #FFF !important;
    height: 87px;
    width: 100%;
}
.loginNavbar a{
    text-decoration: none;
}
.navbarContainer{
    display: flex;
    align-items:flex-start;
    background-color: #fff;
 }
.navMenubar{
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-end;
    padding-top: 18px;
}
.loginLink{
    cursor: pointer;
    margin-right: 10px;
}
.innerMenuContainer{
    display: flex;
    flex-direction: column;
    min-height: 93px;
    justify-content: flex-start;
    width: 380px;
    margin: 0 15px;
}
.innerMenuContainer input, .passwordInputContainer input{
    height: 38px;
    padding: 7px 15px;
    border: 1px solid #CED4DA;
    border-radius: 4px;
    width: 380px;
}
 .checkButton{
    text-align: center;
    width: 216px;
    height: 46px;
    background-color: #000;
    color: #fff !important;
    padding: 12px !important;   
    text-decoration: none; 
    cursor: pointer;
}
.passwordInputContainer{
    display: inline;
    width: 380px !important;
}
.passwordToggleButton{
    width: 23px !important;
    height: 15px;
    margin-left: -34px;
    cursor: pointer;
}
.forgotPwdPara{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-align: right;
    padding-top: 8px;
}
.forgotPwdLink{
    color: #E84E3B;
    text-decoration: underline;
    cursor: pointer;
}
.redirectLoginLink a{
    color: #E84E3B !important;
    text-decoration: underline ;
    cursor: pointer !important;
}
.brandLogo{
    max-width: 365.71px !important;
    max-height: 91.43px !important;
    cursor: pointer;
    /* margin-left: 6%; */
}
.myAccountButton{
    width: 186px !important;
    height: 46px;
    background: #120402 !important;
    border: none !important;
    border-radius: 0 !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 16px;
}
.myAccountButton:hover{
    background-color: #3a3a3a !important;
}
.myAccountButton:focus{    
    box-shadow: none !important;
}
.logoutPara{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-align: right;
    line-height: 21px;
    color: #E84E3B;
    cursor: pointer;
    margin-top: 6px;
}
.logoutPara span{
    text-decoration-line: underline;
}
.mobileLogo{
    width: 10px;
    height: 10px;
    margin-left: 14%;
    display: none;
}
.userNameSpan{
    display: inline-block;
    height: 40px;
    padding-top: 10px;
    margin-right: 20px;
    font-weight: 500;
    font-size: 18px;
    color: #2E2D35;
}

@media screen and (max-width: 860px) {
    .userNameSpan{
        display: none;
    }
    .navigationBar{
        padding: 0 !important;
    }
    .navMenubar{
        width: 94%
    }
    .brandLogo{
        width: 196px;
        height: 102px;
    }
    .checkButton{
        width: 180px;
    }
}
/*mobile view */
@media screen and (max-width: 767px) {
    .userNameSpan{
        display: none;
    }
    .brandLogo{ 
        margin: 0;
        width: 4px;
        height: 4px;    
        display: none;
    }
    .mobileLogo{
        width: 55px;
        height: 55px;
        display: flex;
        align-items: center;
    }
    .navigationBar{
        display: flex;
        flex-direction: column;
        padding: 0;
        margin-bottom: 0;
        list-style: none;
        min-height: 60px;
        justify-content: flex-end;
    }
    .checkButton{
        text-align: center;
        width: 110px;
        height: 32px;
        padding: 6px 0 !important; 
        margin-top: 12px;
        font-size: 14px;
    }
}