.buttonStyle{
    width: 217px !important;
    height: 58px;
    background: #120402 !important;
    border: none !important;
    border-radius: 0 !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    letter-spacing: .08em !important;
}
.buttonStyle:hover{
    background-color: #3a3a3a !important;
}
.buttonStyle:focus{    
    box-shadow: none !important;
}