
.toolTipContainer1, .toolTipContainer2{
    display: inline !important;
}
#tool-tip-1 .tooltip-arrow{
    /* display: none !important; */
    top: 50% !important;
}
#tool-tip-2 .tooltip-arrow{
    /* display: none !important; */
    top: 33% !important;
}
#tool-tip-1 .tooltip-arrow::after{
    display: none !important;
}
#tool-tip-2 .tooltip-arrow::after{
    display: none !important;
}