
.contactContainer{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 0px 20px;
    gap: 10px;
    width: 95%;
    margin: auto;
    margin-top: 20px;
    background: #E9ECEF;
    border: 1px solid #CED4DA;
}
.contactTitleContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    gap: 10px;
    width: 100%;
    height: 59px;
    background: #E84E3B;
    font-weight: 500;
    font-size: 20px;
    line-height: 33px;
    text-align: center;
    color: #FFFFFF;
}
.contactTitleContainerCP{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    gap: 10px;
    width: 100%;
    height: 59px;
    background: #306AC0;
    font-weight: 500;
    font-size: 20px;
    line-height: 33px;
    text-align: center;
    color: #FFFFFF;
}
.contactDetailsContainer{
    max-width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    gap: 10px;
}
.contactDetailsRow{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}
.contactDetailsCol1{
    width: 100px;
    font-weight: 700;
    font-size: 17px;
    line-height: 28px;
    color: #000000;
}
/* .contactDetailsCol2{
    width: 00px;
} */
.addressDetailsContainer{
    display: flex;
    flex-direction: column;
}