.errorOuterContainer{
    background-color: #F0F0F0;
}
.container1{
    height:392px;
    background-image: url('../../../Assets/Images/error_bg_image.svg');
    background-size: cover;
    margin-top: 108px;
    display: flex;
    align-items: center;
}
.innerContainer1{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px 28px 15px;    
    width: 612px;
    height: 233px;    
    background: #120402;
}
.innerContainer1 h4{
    font-family: 'Mukta';
    font-style: normal;
    font-weight: 700 !important;
    font-size: 64px !important;
    display: flex;
    align-items: flex-end;
    color: #E84E3B;
}
.innerContainer1 h6{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700 !important;
    font-size: 24px !important;
    text-align: center;
    color: #E84E3B;
    margin-bottom: 20px !important;
}
.backButtonRed{
    padding: 15px !important;
    width: 217px !important;
    height: 58px !important;
    background: #E84E3B !important;
    border-radius: 99px !important;
    font-family: 'Mukta' !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 17px !important;
    color: #120402 !important;
    border: none !important;
}
.backButtonRed:focus{
    box-shadow: none !important;
}
.para1{
    font-family: 'Mukta';
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 33px;
    text-align: center;
    color: #000000;
    margin: 33px 10px;
}
.backButtonContainer{
    display: flex;
    justify-content: center;
    padding: 0 0 30px;
}

.navigationBar{
    display: flex !important;
    flex-direction: column;
    align-items: center;
    position: absolute !important;
    z-index: 10;
    top: 0;
    background-color: #FFF !important;
    height: 87px;
    width: 100%;
}
.navigationBar a{
    text-decoration: none;
}

.navbarContainer{
    display: flex;
    align-items:flex-start;
    background-color: #fff;
 }
.navMenubar{
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-end;
    padding-top: 18px;
}

.brandLogo{
    max-width: 365.71px !important;
    max-height: 91.43px !important;
    cursor: pointer;
    /* margin-left: 6%; */
}
.mobileLogo{
    width: 10px;
    height: 10px;
    margin-left: 14%;
    display: none;
}
.loginButton{
    width: 217px !important;
    height: 46px;
    background: #120402 !important;
    border: none !important;
    border-radius: 0 !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 16px;
}
.loginButton:hover{
    background-color: #3a3a3a !important;
}
.loginButton:focus{    
    box-shadow: none !important;
}

.footerContainer{
    background: #E84E3B;
    font-family: 'Mukta';
    font-style: normal;
}
.row1{
    display: flex;
    justify-content: space-between;
    padding: 30px 20px ;
    flex-wrap: wrap;
}
.r1col1{
    width: 320px;
}
.r1col2{
    font-weight: 400;
    font-size: 17px;
    line-height: 28px;
    width: 240px;
    color: #fff;
    text-decoration-line: underline;
    text-align: right;
}
.horizontalLine{
    border: 1px solid #FFFFFF;
    margin-top: 0px !important;
}
.row2{
    padding: 10px 20px;
}
.subTitle1, .subTitle2{
    height: 28px;
    font-weight: 700;
    font-size: 17px;
    line-height: 28px;
    color: #FFFFFF;
}

.footerPara{
    max-width: 720px;
    font-size: 17px;
    line-height: 28px;
    color: #FFFFFF;
}
.boldText1{
    font-weight: 700;
}
.logoContainer{
    width: 60px;
    margin: auto;
    display: flex;
    justify-content: space-around;
    margin-bottom: 10px;
}
.logoStyles{
    width: 24px;
    height: 24px;
}
.copyrightContainer{
    width: 250px;
    margin: 10px auto;
    font-weight: 400;
    font-size: 17px;
    line-height: 28px;
    color: #FFFFFF;
}
.copyrightContainer a {
    color: #FFFFFF !important;
}

.addressFooter{
    text-align: center;
    font-weight: 400;
    font-size: 17px;
    line-height: 28px;
    color: #FFFFFF;
}
.r1col2 p{
    cursor: pointer;
}
.r1col2 a{
    color: #FFFFFF !important;
}
.aboutLink{
    cursor: pointer;
}
.footerLinks{
    display: flex;
    width: 520px;
    margin: 0px auto;
    justify-content: space-between;
    padding-bottom: 20px;
}
.footerLinks p{
    font-weight: 400;
    font-size: 17px;
    color: #FFFFFF !important;
    text-decoration: underline;
    cursor: pointer;
}
.footerLinks span{
    font-weight: 400;
    font-size: 17px;
    color: #FFFFFF !important;
}

@media screen and (max-width: 860px) {
    .navigationBar{
        padding: 0 !important;
    }
    .navMenubar{
        width: 94%
    }
    .brandLogo{
        width: 196px;
        height: 102px;
    }
}
/*mobile view */
@media screen and (max-width: 767px) {
    .brandLogo{ 
        margin: 0;
        width: 4px;
        height: 4px;    
        display: none;
    }
    .mobileLogo{
        width: 55px;
        height: 55px;
        display: flex;
        align-items: center;
    }
    .navigationBar{
        display: flex;
        flex-direction: column;
        padding: 0;
        margin-bottom: 0;
        list-style: none;
        min-height: 60px;
        justify-content: flex-end;
    }
    .r1col2{
        padding: 20px 0 0 20px;
        line-height: 22px;
        margin-bottom: 0;
        text-align: left;

    }
    .row2{
        padding: 0 20px;
    }
    .subTitle1{
        height: 56px;
    }
}