.termsContainer{
    background-color: #F0F0F0;
    min-height: 680px;
    
}
.termsInnerContainer{
    padding: 20px;
    margin: auto;
    width: 75%;
    text-align: justify;
}

/*mobile view */
@media screen and (max-width: 768px) {
    .termsInnerContainer{
        padding: 10px;
        margin: auto;
        width: 90%;
        text-align: justify;
    }
}