.outerContainer{
    background-color: #F0F0F0;
    padding: 20px;
}
.createTitle{
    font-weight: 700;
    font-size: 40px;
    line-height: 66px;
    text-align: center !important;
    letter-spacing: -0.02em;
    color: #152536;
}
.packageDetails{
    font-weight: 700;
    font-size: 28px;
    line-height: 47px;
    text-align: center;
    color: #152536;
}
.para1{
    min-height: 47px;
    font-weight: 700;
    font-size: 28px;
    line-height: 47px;
    text-align: center;
    color: #152536;
}
.para2{
    min-height: 28px;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    color: #152536;
}
.selectPaymentDiv{
    margin-top: 30px;
    margin-left: 33%;    
}
.selectPaymentDiv h6{
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    color: #000000;
}
.buttonContainer{
    display: flex;
    justify-content: space-between;
    width: 450px !important;
    margin: 30px 0px 30px 32%;
}
.cancelButton{
    align-items: center;
    padding: 15px;
    gap: 10px;
    width: 217px;
    height: 57px;
    background: #FFFFFF !important;
    border: 2px solid #E84E3B !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    line-height: 27px;
    color: #E84E3B !important ;
    border-radius: 0 !important;
}
.cancelButton:focus{
    box-shadow: none !important;
}
.submitButton{
    align-items: center;
    padding: 15px;
    gap: 10px;
    width: 217px;
    height: 57px;
    background: #E84E3B !important;
    border: 2px solid #E84E3B !important;
    border-radius: 0 !important;
}
.submitButton:focus{
    box-shadow: none !important;
}
.paymentDetailsDiv{
    margin: 30px 0px 30px 34%;
    width: 400px;
}

/*mobile view */
@media screen and (max-width: 576px) {
    .createTitle{
        font-size: 32px;
        line-height: 56px;
        text-align: center !important;
    }
    .packageDetails{
        font-weight: 700;
        font-size: 22px;
        line-height: 36px;
    }
    .para1{
        font-weight: 700;
        font-size: 22px;
        line-height: 36px;
    }
    .para2{
        font-weight: 400;
        font-size: 16px;
        line-height: 28px;
    }

    .selectPaymentDiv{
        margin-left: 10%; 
          
    }
    .buttonContainer{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 320px !important;
        margin: 10px;
    }
    .cancelButton{
        width: 280px;
        margin-bottom: 10px;
    }
    .submitButton{
        width: 280px;
    }
    .paymentDetailsDiv{
        margin: 30px 0px 30px 4%;
        width: 320px;
    }
}