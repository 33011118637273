
.outerContainer{
    background-color: #FFF;
    padding-top: 20px;
}
.topContainer{
    height: 528px;
    width: 100%;
    background: rgba(0, 0, 0, 0.3) url("../../../Assets/Images/wifi-extender-img1.png");
    background-blend-mode: darken;
    padding-left: 40px;
    padding-top: 14%;
    color: #F0F0F0;
}
.topContainer h1{
    font-weight:900 !important;
    font-size: 72px !important;
    width: 50%;
}
.topContainer h5{
    font-weight:700 !important;
    font-size: 28px !important;
    margin-top: 40px;
    width: 75%;
}
.container2{
    display: flex;
    justify-content: center;
    padding: 80px 40px;
    background-color: #f0f0f0;
}
.container2Inner{
    display: flex;
    flex-direction: column;
    width: 70%;
}
.container2Inner h1{
    text-align: left !important;
    font-size: 64px;
    font-weight: 800;
    margin-bottom: 34px;
}
.container3{
    padding: 80px 40px ;
}
.container3 h1{
    font-weight: 800;
    font-size: 2.8rem;
}
.container3Inner{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.container3Col{
    width: 375px;
    margin-top: 40px;
}
.container3Col img{
    width: 375px;
    height: 290px;
    margin-bottom: 28px;
}
.container3InnerCol{
    padding: 0 20px;

}
.buyitButton{
    display: flex;
    background-color: #000000;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 90px ;
    height: 45px ;
    margin-top: 20px;
}
.buyitButton a{
    text-decoration: none;
    color: #FFF;
    font-weight: 700;
    font-size: 1rem;
}
.buyitButton a:hover{
    color: #FFF;
}
.bottomContainer{
    display: flex;
    justify-content: center;
    padding: 100px 40px;
    background-color: #F0F0F0;
    
}
.bottomInnerContainer{
    width: 50%;
    font-size: 1.6rem;
    text-align: center;
}
.bottomInnerContainer h1{
    font-size: 2.6rem;
    font-weight: 800;
    margin-bottom: 40px;
}
.bottomInnerContainer span{
    font-size: 1.2rem;
}

/*mobile view */
@media screen and (max-width: 576px) {
    .topContainer{
        height: 528px;
        padding-left: 10px;
        padding-top: 10%;
        color: #F0F0F0;
    }
    .topContainer h1{
        font-size: 60px !important;
        width: 80%;
    }
    .topContainer h5{
        margin-top: 20px;
        width: 100%;
    }
    .container2{
        padding: 40px 10px;
    }
    .container2Inner{
        width: 100%;
    }
    .container2Inner h1{
        font-size: 50px;
        margin-bottom: 24px;
    }
    .container3{
        padding: 40px 4px ;
    }
    .container3 h1{
        font-weight: 800;
        font-size: 2.3rem;
    }
    .container3Inner{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .container3Col{
        width: 100%;
    }
}