/* input[type=radio]{
    display:none;
} */
.customRadioContainer [type="radio"]:checked,
.customRadioContainer [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
.customRadioContainerCP [type="radio"]:checked,
.customRadioContainerCP [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
.customRadioContainer [type="radio"]:checked + label,
.customRadioContainer [type="radio"]:not(:checked) + label,
.customRadioContainerCP [type="radio"]:checked + label,
.customRadioContainerCP [type="radio"]:not(:checked) + label
{
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #000;
}
.customRadioContainer [type="radio"]:checked + label:before,
.customRadioContainer [type="radio"]:not(:checked) + label:before,
.customRadioContainerCP [type="radio"]:checked + label:before,
.customRadioContainerCP [type="radio"]:not(:checked) + label:before  {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border-radius: 100%;
    background: #fff;
}
.customRadioContainer [type="radio"]:not(:checked) + label:before ,
.customRadioContainerCP [type="radio"]:not(:checked) + label:before {
    border: 1px solid #9EA0A6;

}
.customRadioContainer [type="radio"]:checked + label:after,
.customRadioContainer [type="radio"]:not(:checked) + label:after {
    content: '';
    width: 16px;
    height: 16px;
    background-image: url("../../Assets/Images/radioSelected.png");
    background-size: contain;
    background-position: center;
    position: absolute;
    top: 1px;
    left: 1px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
.customRadioContainerCP [type="radio"]:checked + label:after,
.customRadioContainerCP [type="radio"]:not(:checked) + label:after {
    content: '';
    width: 16px;
    height: 16px;
    background-image: url("../../Assets/Images/blue-radio-button.svg");
    background-size: contain;
    background-position: center;
    position: absolute;
    top: 1px;
    left: 1px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
.customRadioContainer [type="radio"]:checked + label:after{
    border: 1px solid #E84E3B;
}
.customRadioContainerCP [type="radio"]:checked + label:after{
    border: 1px solid #306AC0;
}
.customRadioContainer [type="radio"]:not(:checked) + label:after,
.customRadioContainerCP [type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
.customRadioContainer [type="radio"]:checked + label:after,
.customRadioContainerCP [type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}
.justifyRow{
    display: flex;
    flex-direction: row;
}