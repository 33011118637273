

.statementInnerDiv .react-bootstrap-table thead,
.statementInnerDivCP .react-bootstrap-table thead {
    background-color: #120402;
    color: #FFFFFF;
}
.statementInnerDiv #pageDropDown,
.statementInnerDivCP #pageDropDown{
    display: none ;
    /* width: 50px;
    height: 40px;
    margin-left: 40px;
    color: white !important;
    background-color: #E84D3B !important;
    border: solid 1px #E84D3B !important; */
}
/* .statementInnerDiv #pageDropDown:focus{
    box-shadow: none;
} */
.statementInnerDiv th, .statementInnerDiv td,
.statementInnerDivCP th, .statementInnerDivCP td{
    text-align: center;
    vertical-align: middle;
    max-height: 40px;
}
.statementInnerDiv td:first-child,
.statementInnerDivCP td:first-child{
    width: 40px;
}
.statementInnerDiv td:nth-child(2),
.statementInnerDivCP td:nth-child(2){
    width: 140px;
}
.statementInnerDiv td:nth-child(4),
.statementInnerDivCP td:nth-child(4){
    width: 150px;
}
.statementInnerDiv td:nth-child(5),
.statementInnerDivCP td:nth-child(5){
    width: 150px;
}
.statementInnerDiv td:nth-child(6),
.statementInnerDivCP td:nth-child(6){
    width: 150px;
}
.statementInnerDiv td:nth-child(7),
.statementInnerDivCP td:nth-child(7){
    width: 150px;
}
.statementInnerDiv .react-bootstrap-table-pagination-list,
.statementInnerDivCP .react-bootstrap-table-pagination-list{
    width: 90% !important;
    display: flex;
    justify-content: center ;
}
.statementInnerDiv .pagination > li > a{
    background-color: white;
    color: #E84D3B;
}
.statementInnerDivCP .pagination > li > a{
    background-color: white;
    color: #306AC0;
}
.statementInnerDiv .pagination > li > a:focus,
.statementInnerDiv .pagination > li > a:hover,
.statementInnerDiv .pagination > li > span:focus,
.statementInnerDiv .pagination > li > span:hover
{
    color: #E84D3B;
    background-color: #eee;
    border-color: #ddd;
}
.statementInnerDivCP .pagination > li > a:focus,
.statementInnerDivCP .pagination > li > a:hover,
.statementInnerDivCP .pagination > li > span:focus,
.statementInnerDivCP .pagination > li > span:hover
{
    color: #306AC0;
    background-color: #eee;
    border-color: #ddd;
}

.statementInnerDiv .pagination > .active > a
{
    color: white !important;
    background-color: #E84D3B !important;
    border: solid 1px #E84D3B !important;
}
.statementInnerDivCP .pagination > .active > a
{
    color: white !important;
    background-color: #306AC0 !important;
    border: solid 1px #306AC0 !important;
}

.statementInnerDiv .pagination > .active > a:hover
{
    color: white;
    background-color: #e84d3b;
    border: solid 1px #E84D3B;
}
.statementInnerDivCP .pagination > .active > a:hover
{
    color: white;
    background-color: #306AC0;
    border: solid 1px #306AC0;
}