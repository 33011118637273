.section1{
    display: flex;
    flex-direction: column;
    padding: 14% 0;
    height: 596px;
    background-image: url('../../../Assets/Images/synkro-hiw-01.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
}
.sec1InnerBlock{
    width: 820px;
    height: 240px;
    margin-left: 4%;
    color: #FFF;
}
.block1Title1{
    font-weight: 900;
    font-size: 68px !important;
}
.block1Title2{
    font-weight: 700;
    font-size: 38px !important;
    margin-top: 26px;
}
.section2{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 5% 2% 2% 2%;
    background-color: #F0F0F0;
}
.sec2InnerBlock1{  
    flex: 1;
    padding: 10px 30px;
    line-height: 1.4;
    font-size: 18px;
}
.block2Title{
    font-size: 3rem;
    font-weight: 800;
    margin-bottom: 10px;
}
.sec2InnerBlock2{ 
    flex: 1;
    margin-top: 50px;
}
.towerImage{
    height: 280px;  
    width: 100%;  
}
.section3{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px 40px;
}
.sec3Title{
    font-weight: 800;
    font-size: 46px;
}
.sec3InnerBlock2{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 40px;

}
.column{
    width: 375px;
    text-align: center;
    font-size: 18px;
}
.iconStyle{
    width: 133px;
    height: 133px;
    margin: auto;
    margin-bottom: 30px;
}
.innerPara1{
    font-size: 40px;
    font-weight: 800;
}
.innerPara2{
    font-size: 22px;
    font-weight: 600;
    padding: 10px 20px;
}
.sec3InnerBlock3{
    padding: 50px;
}
.availabilityButton{
    width: 217px !important;
    height: 58px;
    background: #120402 !important;
    border: none !important;
    border-radius: 0 !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    letter-spacing: .08em !important;
}
.availabilityButton:focus{    
    box-shadow: none !important;
}
@media screen and (max-width: 860px) {
    .sec1InnerBlock{
        width: 100%;
        height: 240px;
        margin-left: 0px;
        padding: 6px;
    } 
    .block1Title1{
        font-weight: 900;
        font-size: 52px !important;
    }
    .block1Title2{
        font-weight: 700;
        font-size: 36px !important;
        margin-top: 20px;
    }
    .block2Title{
        font-size: 2.4rem;
    }
    .towerImage{
        height: 380px; 
    }
    .sec3InnerBlock2{
        justify-content: center;
    }
    .sec3Title{
        text-align: center;
    }
}
/*mobile view */
@media screen and (max-width: 576px) {
    .section1{
        height: 490px;
    }
    .block1Title1{
        font-weight: 700;
        font-size: 30px !important;
    }
    .block1Title2{
        font-weight: 700;
        font-size: 28px !important;
        margin-top: 20px;
    }
    .section2{
        height: 590px;
        padding: 10px;
    }
    .sec2InnerBlock1{    
        height: 340px;
        padding: 10px;
        font-size: 16px;
    }
    .towerImage{
        height: 200px; 
        width: 370px;   
    }
    .section3{
        /* height: 696px; */
        padding: 20px;
    }
    
}